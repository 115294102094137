import toastr from 'toastr';
import Service from "../../../utility/Service"


export const PENDING_PAYMENTS_GET_SUCCESS = "PENDING_PAYMENTS_GET_SUCCESS";
export const PENDING_PAYMENTS_GET_FAILURE = "PENDING_PAYMENTS_GET_FAILURE";
export const PENDING_PAYMENTS_GET_REQUEST = "PENDING_PAYMENTS_GET_REQUEST";
export const PENDING_PAYMENTS_PAY_GET_SUCCESS = "PENDING_PAYMENTS_GET_SUCCESS";
export const PENDING_PAYMENTS_PAY_GET_FAILURE = "PENDING_PAYMENTS_GET_FAILURE";
export const PENDING_PAYMENTS_PAY_GET_REQUEST = "PENDING_PAYMENTS_GET_REQUEST";
export const PENDING_PAYMENTS_PAY_GET_RESET = "PENDING_PAYMENTS_GET_RESET";


export const pendingpaymentsGetFailure = () => ({
    type: PENDING_PAYMENTS_GET_FAILURE,
    loading: true,
    pendingpaymentData: []
});


export const pendingpaymentsGetSuccess = (data, pageNo, pageSize) => ({
    type: PENDING_PAYMENTS_GET_SUCCESS,
    loading: false,
    pendingpaymentData: data
});



export const pendingpaymentsGetRequest = () => ({
    type: PENDING_PAYMENTS_GET_REQUEST,
    loading: true,
    pendingpaymentData: []
});

export const getpendingpayments = ( value,pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(pendingpaymentsGetRequest())
    //let url = "payment/charges/status/pending?offset=0&limit=" + 1000
    let url = "payment/charges/filter"
    // if (pageNo && pageSize) {
    //     url = "payment/charges?offset=0&limit=" + 50 + "?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.post(url,value,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                console.log(response);
                let data = {
                    data: response.data,
                    pagination: response.pagination
                }
                dispatch(pendingpaymentsGetSuccess(data));
            } else {
                console.log("plans", response);
                dispatch(pendingpaymentsGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

export const pendingpaymentspayGetFailure = () => ({
    type: PENDING_PAYMENTS_PAY_GET_FAILURE,
    loading: true,
    // pendingpaymentdetailsData: [],
    updated: false
});


export const pendingpaymentspayGetSuccess = () => ({
    type: PENDING_PAYMENTS_PAY_GET_SUCCESS,
    loading: false,
    // pendingpaymentdetailsData: data,
    updated: true
});



export const pendingpaymentspayGetRequest = () => ({
    type: PENDING_PAYMENTS_PAY_GET_REQUEST,
    loading: true,
    // pendingpaymentdetailsData: [],
    updated: false
});


export const pendingpaymentspayReset = () => ({
    type: PENDING_PAYMENTS_PAY_GET_RESET,
    loading: true,
    // pendingpaymentdetailsData: [],
    updated: false
});
export const getpendingpaymentsdetails = (paymentId) => (dispatch, getState) => {
    console.log("payment id", paymentId);
    toastr.remove()
    dispatch(pendingpaymentsGetRequest())
    let url = `payment/charge/${paymentId}/complete`
    let val = {
        tip_cost: 0,
    }
    // if (pageNo && pageSize) {
    //     url = "payment/charges?offset=0&limit=" + 50 + "?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.post(url, val,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                console.log(response);
                // let data = {
                //     data: response.data,
                //     pagination: response.pagination
                // }
                dispatch(pendingpaymentspayGetSuccess());
                dispatch(pendingpaymentspayReset());

            } else {
                console.log("pending payment errors ", response);
                dispatch(pendingpaymentsGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





