import { 
    VEHICLEMAKES_GET_SUCCESS,
    VEHICLEMAKES_GET_FAILURE,
    VEHICLEMAKES_GET_REQUEST,   
    VEHICLEMAKE_GET_SUCCESS,
    VEHICLEMAKE_GET_FAILURE,
    VEHICLEMAKE_GET_REQUEST,
    VEHICLEMAKE_GET_RESET,
    VEHICLEMAKE_DELETE_SUCCESS,
    VEHICLEMAKE_DELETE_FAILURE,
    VEHICLEMAKE_DELETE_REQUEST,
    VEHICLEMAKE_CREATE_SUCCESS,
    VEHICLEMAKE_CREATE_FAILURE,
    VEHICLEMAKE_CREATE_REQUEST,
    VEHICLEMAKE_CREATE_RESET,
    VEHICLEMAKE_UPDATE_SUCCESS,
    VEHICLEMAKE_UPDATE_FAILURE,
    VEHICLEMAKE_UPDATE_REQUEST,
    VEHICLEMAKE_UPDATE_RESET
} from "../actions/makes";

const setGetVehicleMakes = (state = { data: {} }, action) => {
    switch (action.type) {
        case VEHICLEMAKES_GET_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMAKES_GET_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMAKES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetVehicleMake = (state = { data: {} }, action) => {
    switch (action.type) {
        case VEHICLEMAKE_GET_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMAKE_GET_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMAKE_GET_REQUEST:
            return Object.assign({}, action);
        case VEHICLEMAKE_GET_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteVehicleMakes = (state = { deleted: false }, action) => {
    switch (action.type) {
        case VEHICLEMAKE_DELETE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMAKE_DELETE_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMAKE_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreateVehicleMake = (state = { created: false }, action) => {
    switch (action.type) {
        case VEHICLEMAKE_CREATE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMAKE_CREATE_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMAKE_CREATE_REQUEST:
            return Object.assign({}, action);
        case VEHICLEMAKE_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateVehicleMake = (state = { updated: false }, action) => {
    switch (action.type) {
        case VEHICLEMAKE_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMAKE_UPDATE_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMAKE_UPDATE_REQUEST:
            return Object.assign({}, action);
        case VEHICLEMAKE_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetVehicleMakes,
    setGetVehicleMake,
    setDeleteVehicleMakes,
    setCreateVehicleMake,
    setUpdateVehicleMake
};