import toastr from 'toastr';
import Service from "../../../utility/Service"

export const TOTALTAX_GET_SUCCESS = "TOTALTAX_GET_SUCCESS";
export const TOTALTAX_GET_FAILURE = "TOTALTAX_GET_FAILURE";
export const TOTALTAX_GET_REQUEST = "TOTALTAX_GET_REQUEST";

export const taxGetsFailure = () => ({
    type: TOTALTAX_GET_FAILURE,
    loading: true,
    taxesData: []
});


export const taxGetsSuccess = (data, pageNo, pageSize) => ({
    type: TOTALTAX_GET_SUCCESS,
    loading: false,
    taxesData: data
});



export const taxGetsRequest = () => ({
    type: TOTALTAX_GET_REQUEST,
    loading: true,
    taxesData: []
});

export const gettaxes = (pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(taxGetsRequest())
    let url = "tax?offset=0&limit=" + 1000
    // if (pageNo && pageSize) {
    //     url = "payment/charges?offset=0&limit=" + 50 + "?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.get(url,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
              //  console.log(response);
                let data = {
                    data: response.data,
                    pagination: response.pagination
                }
                dispatch(taxGetsSuccess(data));
            } else {
               // console.log("get tax", response);
                dispatch(taxGetsFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





