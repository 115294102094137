import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const VEHICLEMAKE_GET_SUCCESS = "VEHICLEMAKE_GET_SUCCESS";
export const VEHICLEMAKE_GET_FAILURE = "VEHICLEMAKE_GET_FAILURE";
export const VEHICLEMAKE_GET_REQUEST = "VEHICLEMAKE_GET_REQUEST";
export const VEHICLEMAKE_GET_RESET = "VEHICLEMAKE_GET_RESET";
export const VEHICLEMAKES_GET_SUCCESS = "VEHICLEMAKES_GET_SUCCESS";
export const VEHICLEMAKES_GET_FAILURE = "VEHICLEMAKES_GET_FAILURE";
export const VEHICLEMAKES_GET_REQUEST = "VEHICLEMAKES_GET_REQUEST";
export const VEHICLEMAKE_CREATE_RESET = "VEHICLEMAKE_CREATE_RESET";
export const VEHICLEMAKE_CREATE_SUCCESS = "VEHICLEMAKES_CREATE_SUCCESS";
export const VEHICLEMAKE_CREATE_FAILURE = "VEHICLEMAKES_CREATE_FAILURE";
export const VEHICLEMAKE_CREATE_REQUEST = "VEHICLEMAKES_CREATE_REQUEST";
export const VEHICLEMAKE_DELETE_SUCCESS = "VEHICLEMAKE_DELETE_SUCCESS";
export const VEHICLEMAKE_DELETE_FAILURE = "VEHICLEMAKE_DELETE_FAILURE";
export const VEHICLEMAKE_DELETE_REQUEST = "VEHICLEMAKE_DELETE_REQUEST";

export const VEHICLEMAKE_UPDATE_RESET = "VEHICLEMAKE_UPDATE_RESET";
export const VEHICLEMAKE_UPDATE_SUCCESS = "VEHICLEMAKES_UPDATE_SUCCESS";
export const VEHICLEMAKE_UPDATE_FAILURE = "VEHICLEMAKES_UPDATE_FAILURE";
export const VEHICLEMAKE_UPDATE_REQUEST = "VEHICLEMAKES_UPDATE_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';


export const vehicleMakesGetRequest = () => ({
    type: VEHICLEMAKES_GET_REQUEST,
    loading: true,
    data: []
});

export const vehicleMakesGetSuccess = (data, pageNo, pageSize) => ({
    type: VEHICLEMAKES_GET_SUCCESS,
    loading: false,
    data: data
});

export const vehicleMakesGetFailure = () => ({
    type: VEHICLEMAKES_GET_FAILURE,
    loading: false,
    data: []
});


export const getvehiclemakes = (pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    toastr.remove()
    dispatch(vehicleMakesGetRequest())
    Service.get("vehicle/make?offset=0&limit=500",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    vehiclemake: response.data,
                    pagination: response.pagination
                }
                dispatch(vehicleMakesGetSuccess(data));
            } else {
               // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


export const vehicleMakeGetReset = () => ({
    type: VEHICLEMAKE_GET_RESET,
    data: []
});

export const vehicleMakeGetRequest = () => ({
    type: VEHICLEMAKE_GET_REQUEST,
    data: []
});

export const vehicleMakeGetSuccess = (data) => ({
    type: VEHICLEMAKE_GET_SUCCESS,
    data: data
});

export const vehicleMakeGetFailure = () => ({
    type: VEHICLEMAKE_GET_FAILURE,
    data: []
});
/**
 * Get Single Vehicle Make
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getvehiclemake = (vehicleMakeId) => (dispatch, getState) => {
  //  console.log(vehicleMakeId)
    toastr.remove()
    dispatch(vehicleMakeGetRequest())
    Service.get("vehicle/make/" + vehicleMakeId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
             //   console.log(response);
                dispatch(vehicleMakeGetSuccess(response.data));
            } else {
              //  console.log(response);
                dispatch(vehicleMakeGetFailure())
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

export const getvehiclemakebytype = (vehicleTypeId) => (dispatch, getState) => {
    //console.log(vehicleTypeId)
    toastr.remove()
    dispatch(vehicleMakeGetRequest())
    Service.get("vehicle/make",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
               // console.log(response);
                dispatch(vehicleMakeGetSuccess(response.data));
            } else {
               // console.log(response);
                dispatch(vehicleMakeGetFailure())
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


// export const vehicleMakeCreateReset = () => ({
//     type: VEHICLEMAKE_CREATE_RESET,
//     data: []
// });

// export const vehicleMakeCreateRequest = () => ({
//     type: VEHICLEMAKE_CREATE_REQUEST,
//     data: [],
//     loading: true
// });

// export const vehicleMakeCreateSuccess = (data) => ({
//     type: VEHICLEMAKE_CREATE_SUCCESS,
//     data: data
// });

// export const vehicleMakeCreateFailure = () => ({
//     type: VEHICLEMAKE_CREATE_FAILURE,
//     data: []
// });
/**
 * Create Vehicle Make
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
//  export const createVehicleMake = (values) => (dispatch, getState) => {
//     toastr.remove()
//     dispatch(vehicleMakeCreateRequest)
//     Service.post("admin/vehicles/make", values,
//         (response) => {
//             if (response && response.status && response.status >= 200 && response.status < 300) {
//                 toastr.success(response.message ? 'Created Successfully.' : 'Received Successfully');
//                 console.log(response);
//                 dispatch(vehicleMakeCreateSuccess(response.data));
//                 dispatch(vehicleMakeCreateReset());
//             } else {
//                 console.log(response);
//                 dispatch(vehicleMakeCreateFailure())
//                 // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
//                 toastr.error('Something Went Wrong');
//             }
//         }
//     )
// };




export const vehicleMakeCreateReset = (data) => ({
    type: VEHICLEMAKE_CREATE_RESET,
    loading: true
});
export const vehicleMakeCreateRequest = (data) => ({
    type: VEHICLEMAKE_CREATE_REQUEST,
    loading: true
});

export const vehicleMakeCreateSuccess = (data) => ({
    type: VEHICLEMAKE_CREATE_SUCCESS,
    loading: false,
    created: true
});

export const vehicleMakeCreateFailure = (data) => ({
    type: VEHICLEMAKE_CREATE_FAILURE,
    loading: false
});

/**
 * Create  Vehicle Make
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const createVehicleMake = (values) => (dispatch, getState) => {
    toastr.remove()
    //console.log("values of create make", values);
    dispatch(vehicleMakeCreateRequest());
    Service.post("vehicle/make", values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('New Vehicle Make is added Successfully');
                dispatch(vehicleMakeCreateSuccess());
                dispatch(vehicleMakeCreateReset());
                dispatch(vehicleMakeGetReset());
            } else {
                dispatch(vehicleMakeCreateFailure());
                toastr.error(response.message ? response.message : 'Vehicle Make creation is failed');
            }
        }
    )
};



/**
 * Update Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
//  export const updateVehicleMake = (serviceId, values) => (dispatch, getState) => {
//     toastr.remove()
//     Service.put("admin/vehicles/make/"+serviceId, values,
//         (response) => {
//             if (response && response.status && response.status === 200) {
//                 toastr.success('Received Successfully');
//                 console.log(response);
//                 // dispatch(vehicleMakeGetSuccess(response.data.value));
//             } else {
//                 console.log(response);
//                 // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
//                 toastr.error('Something Went Wrong');
//             }
//         }
//     )
// };






export const vehicleMakeUpdateReset = (data) => ({
    type: VEHICLEMAKE_UPDATE_RESET,
    loading: true
});
export const vehicleMakeUpdateRequest = (data) => ({
    type: VEHICLEMAKE_UPDATE_REQUEST,
    loading: true
});

export const vehicleMakeUpdateSuccess = (data) => ({
    type: VEHICLEMAKE_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const vehicleMakeUpdateFailure = (data) => ({
    type: VEHICLEMAKE_UPDATE_FAILURE,
    loading: false
});

/**
 * Update  Vehicle Make
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateVehicleMake = (serviceId, values) => (dispatch, getState) => {
   // console.log(serviceId)
   // console.log("serviceId")
    toastr.remove()
    //console.log(values);
    dispatch(vehicleMakeUpdateRequest());
    Service.patch("vehicle/make/" + serviceId, values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('Vehicle Make is Updated Successfully');
                dispatch(vehicleMakeUpdateSuccess());
                dispatch(vehicleMakeUpdateReset());
                dispatch(vehicleMakeGetReset());
            } else {
                dispatch(vehicleMakeUpdateFailure());
                toastr.error(response.message ? response.message : 'Vehicle Make updation is failed');
            }
        }
    )
};





/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteRequest = (data) => ({
    type: VEHICLEMAKE_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteSuccess = (data) => ({
    type: VEHICLEMAKE_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteFailure = () => ({
    type: VEHICLEMAKE_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deletevehiclemakes = (serviceId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(serviceId);
    toastr.remove()
    dispatch(serviceDeleteRequest());
    Service.delete("vehicle/make/" + serviceId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? "Successfully Deleted the Use" : 'Successfully Deleted the Use');
                //console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getvehiclemakes(pageNo, pageSize));
            } else {
               // console.log(response);
                dispatch(serviceDeleteFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error(response.message ? response.message : 'Something Went Wrong');
            }
        }
    )
};
