import {
    TAX_GET_SUCCESS,
    TAX_GET_FAILURE,
    TAX_GET_REQUEST
} from "../actions/tax";


const setGetTax = (state = { taxData: {} }, action) => {
    switch (action.type) {
        case TAX_GET_SUCCESS:
            return Object.assign({}, action);
        case TAX_GET_FAILURE:
            return Object.assign({}, action);
        case TAX_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetTax,

};