import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from "redux-thunk";
import reducer from "./reducers/index";


const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return [thunk];
    } else {
        // Enable additional logging in non-production environments.
        return [thunk, createLogger()]
    }
};
const middleware = getMiddleware();

const store = createStore(reducer, applyMiddleware(...middleware));
export default store