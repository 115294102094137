import {
    CANCELLED_ORDERS_GET_SUCCESS,
    CANCELLED_ORDERS_GET_FAILURE,
    CANCELLED_ORDERS_GET_REQUEST,
} from "../actions/cancelledorders";

const setGetCancelledOrders = (state = { completedOrderData: {} }, action) => {
    switch (action.type) {
        case CANCELLED_ORDERS_GET_SUCCESS:
            return Object.assign({}, action);
        case CANCELLED_ORDERS_GET_FAILURE:
            return Object.assign({}, action);
        case CANCELLED_ORDERS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetCancelledOrders
}
