import toastr from 'toastr';
import Service from "../../../utility/Service"

export const UPDATE_TAX_GET_SUCCESS = "UPDATE_TAX_GET_SUCCESS";
export const UPDATE_TAX_GET_FAILURE = "UPDATE_TAX_GET_FAILURE";
export const UPDATE_TAX_GET_REQUEST = "UPDATE_TAX_GET_REQUEST";
export const UPDATE_TAX_GET_RESET = "UPDATE_TAX_GET_RESET";


export const taxUpdateFailure = () => ({
    type: UPDATE_TAX_GET_FAILURE,
    loading: true,
    update: false,
    taxUpdatedData: []
});

export const taxUpdateSuccess = (data, pageNo, pageSize) => ({
    type: UPDATE_TAX_GET_SUCCESS,
    loading: false,
    update: true,
    taxUpdatedData: data
});

export const taxUpdateRequest = () => ({
    type: UPDATE_TAX_GET_REQUEST,
    loading: true,
    update: false,
    taxUpdatedData: []
});

export const taxUpdateReset = () => ({
    type: UPDATE_TAX_GET_RESET,
    loading: false,
    update: false,
    taxUpdatedData: []
});

export const updatetax = (state, values, pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(taxUpdateRequest())
    let url = "tax/" + state
    // if (pageNo && pageSize) {
    //     url = "payment/charges?offset=0&limit=" + 50 + "?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.patch(url, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
               // console.log(response);
                let data = {
                    data: response.data,
                    pagination: response.pagination
                }
                dispatch(taxUpdateSuccess(data));
                dispatch(taxUpdateReset());

            } else {
               // console.log("taxes", response);
                dispatch(taxUpdateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





