import { 
    ROLES_GET_SUCCESS,
    ROLES_GET_FAILURE,
    ROLES_GET_REQUEST,
    ROLE_GET_SUCCESS,
    ROLE_GET_FAILURE,
    ROLE_GET_REQUEST,
    ROLE_CREATE_RESET,
    ROLE_CREATE_SUCCESS,
    ROLE_CREATE_FAILURE,
    ROLE_CREATE_REQUEST,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_FAILURE,
    ROLE_UPDATE_REQUEST,
    ROLE_DELETE_SUCCESS,
    ROLE_DELETE_FAILURE,
    ROLE_DELETE_REQUEST,
    PERMISSIONS_GET_SUCCESS,
    PERMISSIONS_GET_FAILURE,
    PERMISSIONS_GET_REQUEST
} from "../actions/roles";

const setGetRoles = (state = { data: {} }, action) => {
    switch (action.type) {
        case ROLES_GET_SUCCESS:
            return Object.assign({}, action);
        case ROLES_GET_FAILURE:
            return Object.assign({}, action);
        case ROLES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setGetPermissions = (state = { data: {} }, action) => {
    switch (action.type) {
        case PERMISSIONS_GET_SUCCESS:
            return Object.assign({}, action);
        case PERMISSIONS_GET_FAILURE:
            return Object.assign({}, action);
        case PERMISSIONS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetRole = (state = { data: {} }, action) => {
    switch (action.type) {
        case ROLE_GET_SUCCESS:
            return Object.assign({}, action);
        case ROLE_GET_FAILURE:
            return Object.assign({}, action);
        case ROLE_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setCreateRole = (state = { created: false }, action) => {
    switch (action.type) {
        case ROLE_CREATE_SUCCESS:
            return Object.assign({}, action);
        case ROLE_CREATE_FAILURE:
            return Object.assign({}, action);
        case ROLE_CREATE_REQUEST:
            return Object.assign({}, action);
        case ROLE_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setUpdateRole = (state = { updated: false }, action) => {
    switch (action.type) {
        case ROLE_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case ROLE_UPDATE_FAILURE:
            return Object.assign({}, action);
        case ROLE_UPDATE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteRole = (state = { deleted: false }, action) => {
    switch (action.type) {
        case ROLE_DELETE_SUCCESS:
            return Object.assign({}, action);
        case ROLE_DELETE_FAILURE:
            return Object.assign({}, action);
        case ROLE_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetRoles,
    setGetRole,
    setCreateRole,
    setUpdateRole,
    setDeleteRole,
    setGetPermissions
};