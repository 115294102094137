import {
    // USER_RESET,
    USERS_GET_SUCCESS,
    USERS_GET_FAILURE,
    USERS_GET_REQUEST,
    USER_GET_SUCCESS,
    USER_GET_FAILURE,
    USER_GET_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILURE,
    USER_UPDATE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAILURE,
    USER_DELETE_REQUEST
} from "../actions/users";

const setGetUsers = (state = { userData: {} }, action) => {
    switch (action.type) {
        case USERS_GET_SUCCESS:
            return Object.assign({}, action);
        case USERS_GET_FAILURE:
            return Object.assign({}, action);
        case USERS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetUser = (state = { userData: {} }, action) => {
    switch (action.type) {
        // case USER_RESET:
        //     return Object.assign({}, action);
        case USER_GET_SUCCESS:
            return Object.assign({}, action);
        case USER_GET_FAILURE:
            return Object.assign({}, action);
        case USER_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateUser = (state = { updated: false }, action) => {
    switch (action.type) {
        // case USER_RESET:
        //     return Object.assign({}, action);
        case USER_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case USER_UPDATE_FAILURE:
            return Object.assign({}, action);
        case USER_UPDATE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteUser = (state = { deleted: false }, action) => {
    switch (action.type) {
        case USER_DELETE_SUCCESS:
            return Object.assign({}, action);
        case USER_DELETE_FAILURE:
            return Object.assign({}, action);
        case USER_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};



export {
    setGetUsers,
    setGetUser,
    setUpdateUser,
    setDeleteUser,
};
