import {
    PENDING_PAYMENTS_GET_SUCCESS,
    PENDING_PAYMENTS_GET_FAILURE,
    PENDING_PAYMENTS_GET_REQUEST,
    PENDING_PAYMENTS_PAY_GET_FAILURE,
    PENDING_PAYMENTS_PAY_GET_SUCCESS,
    PENDING_PAYMENTS_PAY_GET_REQUEST,
    PENDING_PAYMENTS_PAY_GET_RESET

} from "../actions/pendingpayments";


const setGetPendingPayments = (state = { pendingpaymentData: {} }, action) => {
    switch (action.type) {
        case PENDING_PAYMENTS_GET_SUCCESS:
            return Object.assign({}, action);
        case PENDING_PAYMENTS_GET_FAILURE:
            return Object.assign({}, action);
        case PENDING_PAYMENTS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setGetPendingDetailsPayments = (state = { updated: false }, action) => {
    switch (action.type) {
        case PENDING_PAYMENTS_PAY_GET_SUCCESS:
            return Object.assign({}, action);
        case PENDING_PAYMENTS_PAY_GET_FAILURE:
            return Object.assign({}, action);
        case PENDING_PAYMENTS_PAY_GET_REQUEST:
            return Object.assign({}, action);
        case PENDING_PAYMENTS_PAY_GET_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetPendingPayments,
    setGetPendingDetailsPayments,

};