import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const SERVICE_GET_SUCCESS = "SERVICE_GET_SUCCESS";
export const SERVICE_GET_FAILURE = "SERVICE_GET_FAILURE";
export const SERVICE_GET_REQUEST = "SERVICE_GET_REQUEST";
export const SERVICES_GET_SUCCESS = "SERVICES_GET_SUCCESS";
export const SERVICES_GET_FAILURE = "SERVICES_GET_FAILURE";
export const SERVICES_GET_REQUEST = "SERVICES_GET_REQUEST";
export const SERVICE_DELETE_SUCCESS = "SERVICE_DELETE_SUCCESS";
export const SERVICE_DELETE_FAILURE = "SERVICE_DELETE_FAILURE";
export const SERVICE_DELETE_REQUEST = "SERVICE_DELETE_REQUEST";
export const SERVICE_CREATE_REQUEST = "SERVICE_CREATE_REQUEST";
export const SERVICE_CREATE_SUCCESS = "SERVICE_CREATE_SUCCESS";
export const SERVICE_CREATE_RESET = "SERVICE_CREATE_RESET";
export const SERVICE_CREATE_FAILURE = "SERVICE_CREATE_FAILURE";
export const SERVICE_UPDATE_REQUEST = "SERVICE_UPDATE_REQUEST";
export const SERVICE_UPDATE_SUCCESS = "SERVICE_UPDATE_SUCCESS";
export const SERVICE_UPDATE_FAILURE = "SERVICE_UPDATE_FAILURE";
export const SERVICE_UPDATE_RESET = "SERVICE_UPDATE_RESET";


// import Toaster from '../views/notifications/toaster/Toaster';

/**
 * All services Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const servicesGetRequest = () => ({
    type: SERVICES_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * All services Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const servicesGetSuccess = (data, pageNo, pageSize) => ({
    type: SERVICES_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceserviceGetFailure = () => ({
    type: SERVICES_GET_FAILURE,
    loading: false,
    data: []
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getservices = () => (dispatch) => {
    toastr.remove()
    dispatch(servicesGetRequest())
    Service.get("/service?populate=plans?offset=0&limit=500",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    services: response.data,
                    pagination: response.pagination
                }
                dispatch(servicesGetSuccess(data));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceGetRequest = () => ({
    type: SERVICE_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceGetSuccess = (data) => ({
    type: SERVICE_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceGetFailure = (data) => ({
    type: SERVICE_GET_FAILURE,
    loading: false,
    data: data
});

/**
 * Get Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getservice = (serviceId) => (dispatch, getState) => {
    toastr.remove()
    dispatch(serviceGetRequest());
    Service.get("service/" + serviceId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(serviceGetSuccess(response.data));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};




export const createServiceRequest = () => ({
    type: SERVICE_CREATE_REQUEST,
    loading: true,
    created: false
});

export const createServiceSuccess = (data) => ({
    type: SERVICE_CREATE_SUCCESS,
    loading: false,
    created: true
});
export const createServiceReset = (data) => ({
    type: SERVICE_CREATE_RESET,
    loading: false,
    created: false
});
export const createServiceFailure = () => ({
    type: SERVICE_CREATE_FAILURE,
    loading: false
});

/**
 * Create Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const createService = (values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(createServiceRequest());
    Service.post("service", values,
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Created Successfully');
                console.log(response);
                dispatch(createServiceSuccess());
                dispatch(createServiceReset());
                // dispatch(serviceGetSuccess(response.data.value));
            } else {
                console.log(response);
                dispatch(createServiceFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

export const ServiceUpdateReset = (data) => ({
    type: SERVICE_UPDATE_RESET,
    loading: true,
    updated: false

});
export const ServiceUpdateRequest = (data) => ({
    type: SERVICE_UPDATE_REQUEST,
    loading: true,
    updated: false

});

export const ServiceUpdateSuccess = (data) => ({
    type: SERVICE_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const ServiceUpdateFailure = (data) => ({
    type: SERVICE_UPDATE_FAILURE,
    loading: false,
    updated: false

});

/**
 * Update Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateService = (serviceId, values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(ServiceUpdateRequest());

    Service.patch("service/" + serviceId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(ServiceUpdateSuccess());
                dispatch(ServiceUpdateReset());
                // dispatch(serviceGetSuccess(response.data.value));
            } else {
                console.log(response);
                dispatch(ServiceUpdateFailure());

                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteRequest = (data) => ({
    type: SERVICE_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteSuccess = (data) => ({
    type: SERVICE_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteFailure = () => ({
    type: SERVICE_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deleteservice = (serviceId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(serviceId);
    toastr.remove()
    dispatch(serviceDeleteRequest());
    Service.delete("service/" + serviceId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Service');
                console.log(response);
                dispatch(serviceDeleteSuccess());
                dispatch(getservices(pageNo, pageSize));
            } else {
                dispatch(serviceDeleteFailure());
                toastr.error(response?.message || 'Something Went Wrong');
            }
        }
    )
};

