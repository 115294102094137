import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const VEHICLEMODEL_GET_SUCCESS = "VEHICLEMODEL_GET_SUCCESS";
export const VEHICLEMODEL_GET_FAILURE = "VEHICLEMODEL_GET_FAILURE";
export const VEHICLEMODEL_GET_REQUEST = "VEHICLEMODEL_GET_REQUEST";
export const VEHICLEMODELS_GET_SUCCESS = "VEHICLEMODELS_GET_SUCCESS";
export const VEHICLEMODELS_GET_FAILURE = "VEHICLEMODELS_GET_FAILURE";
export const VEHICLEMODELS_GET_REQUEST = "VEHICLEMODELS_GET_REQUEST";
export const VEHICLEMODEL_GET_RESET = "VEHICLEMODEL_GET_RESET";
export const VEHICLEMODEL_DELETE_SUCCESS = "VEHICLEMODEL_DELETE_SUCCESS";
export const VEHICLEMODEL_DELETE_FAILURE = "VEHICLEMODEL_DELETE_FAILURE";
export const VEHICLEMODEL_DELETE_REQUEST = "VEHICLEMODEL_DELETE_REQUEST";
export const VEHICLEMODEL_CREATE_SUCCESS = "VEHICLEMODEL_CREATE_SUCCESS";
export const VEHICLEMODEL_CREATE_FAILURE = "VEHICLEMODEL_CREATE_FAILURE";
export const VEHICLEMODEL_CREATE_REQUEST = "VEHICLEMODEL_CREATE_REQUEST";
export const VEHICLEMODEL_CREATE_RESET = "VEHICLEMODEL_CREATE_RESET";
export const VEHICLEMODEL_UPDATE_SUCCESS = "VEHICLEMODEL_UPDATE_SUCCESS";
export const VEHICLEMODEL_UPDATE_FAILURE = "VEHICLEMODEL_UPDATE_FAILURE";
export const VEHICLEMODEL_UPDATE_REQUEST = "VEHICLEMODEL_UPDATE_REQUEST";
export const VEHICLEMODEL_UPDATE_RESET = "VEHICLEMODEL_UPDATE_RESET";


// import Toaster from '../views/notifications/toaster/Toaster';


export const vehicleModelsGetRequest = () => ({
    type: VEHICLEMODELS_GET_REQUEST,
    loading: true,
    data: []
});

export const vehicleModelsGetSuccess = (data, pageNo, pageSize) => ({
    type: VEHICLEMODELS_GET_SUCCESS,
    loading: false,
    data: data
});

export const vehicleModelsGetFailure = () => ({
    type: VEHICLEMODELS_GET_FAILURE,
    loading: false,
    data: []
});


export const getvehiclemodels = (pageNo = 1, pageSize = 20) => (dispatch, getState) => {
    toastr.remove()
    dispatch(vehicleModelsGetRequest())
    Service.get("vehicle/gear?offset=0&limit=500",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    vehiclemodel: response.data,
                    pagination: response.pagination
                }
                dispatch(vehicleModelsGetSuccess(data));
            } else {
               // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



export const vehicleModelGetRequest = () => ({
    type: VEHICLEMODEL_GET_REQUEST,
    data: []
});

export const vehicleModelGetSuccess = (data) => ({
    type: VEHICLEMODEL_GET_SUCCESS,
    data: data
});

export const vehicleModelGetFailure = () => ({
    type: VEHICLEMODEL_GET_FAILURE,
    data: []
});
export const vehicleModelGetReset = () => ({
    type: VEHICLEMODEL_GET_RESET,
    data: []
});
/**
 * Get Single Vehicle Model
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getvehiclemodel = (vehicleModelId) => (dispatch, getState) => {
   // console.log("model-id", vehicleModelId)
    toastr.remove()
    dispatch(vehicleModelGetRequest())
    Service.get("vehicle/gear/" + vehicleModelId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
              //  console.log(response);
                dispatch(vehicleModelGetSuccess(response.data));
            } else {
               // console.log(response);
                dispatch(vehicleModelGetFailure())
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};




export const vehicleModelCreateReset = (data) => ({
    type: VEHICLEMODEL_CREATE_RESET,
    loading: true
});
export const vehicleModelCreateRequest = (data) => ({
    type: VEHICLEMODEL_CREATE_REQUEST,
    loading: true
});

export const vehicleModelCreateSuccess = (data) => ({
    type: VEHICLEMODEL_CREATE_SUCCESS,
    loading: false,
    created: true
});

export const vehicleModelCreateFailure = (data) => ({
    type: VEHICLEMODEL_CREATE_FAILURE,
    loading: false
});

/**
 * Create  Vehicle Make
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const createVehicleModel = (values) => (dispatch, getState) => {
    dispatch(vehicleModelUpdateReset());
    toastr.remove()
   // console.log(values);
    dispatch(vehicleModelCreateRequest());
    Service.post("vehicle/gear", values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('New Vehicle Model is added Successfully');
                dispatch(vehicleModelCreateSuccess());
                dispatch(vehicleModelCreateReset());
                dispatch(vehicleModelGetReset());
            } else {
                dispatch(vehicleModelCreateFailure());
                toastr.error(response.message ? response.message : 'Vehicle Model creation is failed');
            }
        }
    )
};

// /**
//  * Create Vehicle Model
//  * @param  {[type]} fields [description]
//  * @return {[type]}        [description]
//  */
//  export const createVehicleModel = (values) => (dispatch, getState) => {
//     toastr.remove()
//     Service.post("admin/vehicles/model", values,
//         (response) => {
//             if (response && response.status && response.status >= 200 && response.status < 300) {
//                 toastr.success(response.message ? 'Created Successfully.' : 'Received Successfully');
//                 console.log(response);
//                 dispatch(vehicleModelGetSuccess(response.data));
//             } else {
//                 console.log(response);
//                 // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
//                 toastr.error('Something Went Wrong');
//             }
//         }
//     )
// };



/**
 * Update Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
//  export const updateVehicleModel = (serviceId, values) => (dispatch, getState) => {
//     toastr.remove()
//     Service.put("admin/vehicles/model/"+serviceId, values,
//         (response) => {
//             if (response && response.status && response.status === 200) {
//                 toastr.success('Received Successfully');
//                 console.log(response);
//                 // dispatch(vehicleModelGetSuccess(response.data.value));
//             } else {
//                 console.log(response);
//                 // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
//                 toastr.error('Something Went Wrong');
//             }
//         }
//     )
// };




export const vehicleModelUpdateReset = () => ({
    type: VEHICLEMODEL_UPDATE_RESET,
    loading: true
});
export const vehicleModelUpdateRequest = (data) => ({
    type: VEHICLEMODEL_UPDATE_REQUEST,
    loading: true
});

export const vehicleModelUpdateSuccess = (data) => ({
    type: VEHICLEMODEL_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const vehicleModelUpdateFailure = (data) => ({
    type: VEHICLEMODEL_UPDATE_FAILURE,
    loading: false
});

/**
 * Update  Vehicle Make
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateVehicleModel = (serviceId, values) => (dispatch, getState) => {
    toastr.remove()
   // console.log(values);
    dispatch(vehicleModelUpdateRequest());
    Service.patch("vehicle/gear/" + serviceId, values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('Vehicle Model is Updated Successfully');
                dispatch(vehicleModelUpdateSuccess());
                dispatch(vehicleModelUpdateReset());
                dispatch(vehicleModelGetReset());
            } else {
                dispatch(vehicleModelUpdateFailure());
                toastr.error(response.message ? response.message : 'Vehicle Model updation is failed');
            }
        }
    )
};






/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteRequest = (data) => ({
    type: VEHICLEMODEL_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteSuccess = (data) => ({
    type: VEHICLEMODEL_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteFailure = () => ({
    type: VEHICLEMODEL_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deletevehiclemodels = (serviceId, pageNo, pageSize) => (dispatch, getState) => {
   // console.log(serviceId);
    toastr.remove()
    dispatch(serviceDeleteRequest());
    Service.delete("vehicle/model/" + serviceId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? "Successfully Deleted the Use" : 'Successfully Deleted the Use');
               // console.log(response);
                dispatch(serviceDeleteSuccess(response.data.value));
                dispatch(getvehiclemodels(pageNo, pageSize));
            } else {
               // console.log(response);
                dispatch(serviceDeleteFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error(response.message ? response.message : 'Something Went Wrong');
            }
        }
    )
};
