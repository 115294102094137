import toastr from 'toastr';

import Service from "../../../utility/Service"

import { gettaxes } from '../actions/totaltax'

export const TAX_DELETE_SUCCESS = "TAX_DELETE_SUCCESS";
export const TAX_DELETE_FAILURE = "TAX_DELETE_FAILURE";
export const TAX_DELETE_REQUEST = "TAX_DELETE_REQUEST";


export const taxDeleteRequest = (data) => ({
    type: TAX_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const taxDeleteSuccess = (data) => ({
    type: TAX_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const taxDeleteFailure = () => ({
    type: TAX_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deletetax = (taxId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(serviceId);
    toastr.remove()
    dispatch(taxDeleteRequest());
    Service.delete("tax/" + taxId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Service');
              //  console.log(response);
                dispatch(taxDeleteSuccess());
                dispatch(gettaxes(pageNo, pageSize));
            } else {
                dispatch(taxDeleteFailure());
                toastr.error(response?.message || 'Something Went Wrong');
            }
        }
    )
};
