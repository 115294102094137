
import toastr from 'toastr';
import Service from "../../../utility/Service"


export const COMPLETE_PAYMENTS_GET_SUCCESS = "COMPLETE_PAYMENTS_GET_SUCCESS";
export const COMPLETE_PAYMENTS_GET_FAILURE = "COMPLETE_PAYMENTS_GET_FAILURE";
export const COMPLETE_PAYMENTS_GET_REQUEST = "COMPLETE_PAYMENTS_GET_REQUEST";

export const completepaymentsGetFailure = () => ({
    type: COMPLETE_PAYMENTS_GET_FAILURE,
    loading: true,
    completepaymentData: []
});


export const completepaymentsGetSuccess = (data, pageNo, pageSize) => ({
    type: COMPLETE_PAYMENTS_GET_SUCCESS,
    loading: false,
    completepaymentData: data
});



export const completepaymentsGetRequest = () => ({
    type: COMPLETE_PAYMENTS_GET_REQUEST,
    loading: true,
    completepaymentData: []
});

export const getcompletepayments = (value,pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(completepaymentsGetRequest())
    //let url = "payment/charges/status/succeeded?offset=0&limit=" + 1000
    let url = "payment/charges/filter"
    // if (pageNo && pageSize) {
    //     url = "payment/charges?offset=0&limit=" + 50 + "?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.post(url,value,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                console.log(response);
                let data = {
                    data: response.data,
                    pagination: response.pagination
                }
                dispatch(completepaymentsGetSuccess(data));
            } else {
                console.log("complete payment failure response", response);
                dispatch(completepaymentsGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





