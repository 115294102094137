import axios from 'axios';
// import Utility from './Utility';
import { getToken } from '../middleware/Authentication';
import Env from "./env";

class Service {
  constructor() {
    // let service = axios.create({
    //     headers: this.getHeaders()
    // });
    let service = axios.create();
    service.interceptors.response.use((response) => response, (error) => {
      if (typeof error.response === 'undefined') {
        // alert('A network error occurred. '
        //     + 'This could be a CORS issue or a dropped internet connection. '
        //     + 'It is not possible for us to know.')

        console.log('A network error occurred. '
          + 'This could be a CORS issue or a dropped internet connection. '
          + 'It is not possible for us to know.')
      }
      return Promise.reject(error)
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);

    this.service = service;
    this.resetFactory(service);
  }

  resetFactory(service) {
    this.service = service;
  }

  getHeaders() {
    const token = JSON.parse(localStorage.getItem('auth'));
    let headers = {}
    if (token) {
      headers = {
        "Content-Type": "application/json",
        'x-access-token': `${token}`,
        "x-request-time": new Date().toString(),
        "x-request-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "Access-Control-Allow-Origin": "*"
      }
    }
    return headers
  }

  handleSuccess(response) {
    console.log(response)
    let data = {
      status: response.status,
      message: response.message ? response.message : "",
      // data: Utility.isJson(response.data) ? JSON.parse(response.data) : ''
      data: response?.data
    };
    if (response.headers['x-pagination']) {
      data.pagination = JSON.parse(response.headers['x-pagination'])
    }
    return data
  }

  getUrl(url) {
    return Env.getEnv('REACT_APP_BACKEND_URL') + Env.getEnv('REACT_APP_API_BASEURL') + url;
  }

  handleError = (error) => {
    let data = {
      status: error.response?.status,
      message: error.response?.data?.message ? error.response.data.message : "",
      data: error.response?.data
    };
    // switch (error.response.status) {
    //   case 401:
    //     this.redirectTo(document, '/')
    //     break;
    //   case 404:
    //     this.redirectTo(document, '/404')
    //     break;
    //   case 403:
    //     this.redirectTo(document, '/403')
    //     break;
    //   case 422:
    //     this.redirectTo(document, '/403')
    //     break;
    //   default:
    //     this.redirectTo(document, '/500')
    //     break;
    // }
    // return Promise.reject(error)
    return data;
  }

  redirectTo = (document, path) => {
    document.location = path
  }

  get(path, callback) {
    return this.service.get(this.getUrl(path), {
      headers: this.getHeaders()
    }).then(
      (response) => callback(response)
    );
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: this.getUrl(path),
      responseType: 'json',
      data: payload,
      headers: this.getHeaders()
    }).then(
      (response) => callback(response)
    );
  }

  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: this.getUrl(path),
      responseType: 'json',
      data: payload,
      headers: this.getHeaders()
    }).then(
      (response) => callback(response)
    );
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: this.getUrl(path),
      responseType: 'json',
      data: payload,
      headers: this.getHeaders()
    }).then(
      (response) => callback(response)
    );
  }

  delete(path, payload, callback) {
    return this.service.request({
      method: 'DELETE',
      url: this.getUrl(path),
      responseType: 'json',
      data: payload,
      headers: this.getHeaders()
    }).then(
      (response) => callback(response)
    );
  }
}

export default new Service();