import toastr from 'toastr';
import Service from "../../../utility/Service"
import { TbBuildingLighthouse } from 'react-icons/tb';

export const TAX_GET_SUCCESS = "TAX_GET_SUCCESS";
export const TAX_GET_FAILURE = "TAX_GET_FAILURE";
export const TAX_GET_REQUEST = "TAX_GET_REQUEST";

export const taxGetFailure = () => ({
    type: TAX_GET_FAILURE,
    loading: true,
    taxData: []
});


export const taxGetSuccess = (data, pageNo, pageSize) => ({
    type: TAX_GET_SUCCESS,
    loading: false,
    taxData: data
});



export const taxGetRequest = () => ({
    type: TAX_GET_REQUEST,
    loading: true,
    taxData: []
});

export const gettax = (state, pageNo, pageSize) => (dispatch, getState) => {
    console.log("state for get tax by state", state);
    toastr.remove()
    dispatch(taxGetRequest())
    let url = "tax/state/" + state
    // if (pageNo && pageSize) {
    //     url = "payment/charges?offset=0&limit=" + 50 + "?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.get(url,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
              //  console.log(response);
                let data = {
                    data: response.data,
                    pagination: response.pagination
                }
                dispatch(taxGetSuccess(data));
            } else {
               // console.log("get tax", response);
                dispatch(taxGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





