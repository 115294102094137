import {
    UPDATE_TAX_GET_SUCCESS,
    UPDATE_TAX_GET_FAILURE,
    UPDATE_TAX_GET_REQUEST,
    UPDATE_TAX_GET_RESET
} from "../actions/edittax";


const setUpdateTax = (state = { update: false }, action) => {
    switch (action.type) {
        case UPDATE_TAX_GET_SUCCESS:
            return Object.assign({}, action);
        case UPDATE_TAX_GET_FAILURE:
            return Object.assign({}, action);
        case UPDATE_TAX_GET_REQUEST:
            return Object.assign({}, action);
        case UPDATE_TAX_GET_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};
export {
    setUpdateTax,

};