import {
    SERVICES_GET_SUCCESS,
    SERVICES_GET_FAILURE,
    SERVICES_GET_REQUEST,
    SERVICE_GET_SUCCESS,
    SERVICE_GET_FAILURE,
    SERVICE_GET_REQUEST,
    SERVICE_DELETE_SUCCESS,
    SERVICE_DELETE_FAILURE,
    SERVICE_DELETE_REQUEST,
    SERVICE_CREATE_SUCCESS,
    SERVICE_CREATE_FAILURE,
    SERVICE_CREATE_REQUEST,
    SERVICE_CREATE_RESET,
    SERVICE_UPDATE_REQUEST,
    SERVICE_UPDATE_SUCCESS,
    SERVICE_UPDATE_RESET,
    SERVICE_UPDATE_FAILURE
} from "../actions/services";

const setGetServices = (state = { data: {} }, action) => {
    switch (action.type) {
        case SERVICES_GET_SUCCESS:
            return Object.assign({}, action);
        case SERVICES_GET_FAILURE:
            return Object.assign({}, action);
        case SERVICES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetService = (state = { data: {} }, action) => {
    switch (action.type) {
        case SERVICE_GET_SUCCESS:
            return Object.assign({}, action);
        case SERVICE_GET_FAILURE:
            return Object.assign({}, action);
        case SERVICE_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreateService = (state = { created: false }, action) => {
    switch (action.type) {
        case SERVICE_CREATE_SUCCESS:
            return Object.assign({}, action);
        case SERVICE_CREATE_FAILURE:
            return Object.assign({}, action);
        case SERVICE_CREATE_REQUEST:
            return Object.assign({}, action);
        case SERVICE_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteService = (state = { deleted: false }, action) => {
    switch (action.type) {
        case SERVICE_DELETE_SUCCESS:
            return Object.assign({}, action);
        case SERVICE_DELETE_FAILURE:
            return Object.assign({}, action);
        case SERVICE_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateService = (state = { updated: false }, action) => {
    switch (action.type) {
        case SERVICE_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case SERVICE_UPDATE_FAILURE:
            return Object.assign({}, action);
        case SERVICE_UPDATE_REQUEST:
            return Object.assign({}, action);
        case SERVICE_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetServices,
    setGetService,
    setDeleteService,
    setCreateService,
    setUpdateService

};
