import { 
    IMAGES_GET_SUCCESS,
    IMAGES_GET_FAILURE,
    IMAGES_GET_REQUEST,
    IMAGE_GET_SUCCESS,
    IMAGE_GET_FAILURE,
    IMAGE_GET_REQUEST,
    IMAGE_CREATE_RESET,
    IMAGE_CREATE_SUCCESS,
    IMAGE_CREATE_FAILURE,
    IMAGE_CREATE_REQUEST,
    IMAGE_UPDATE_SUCCESS,
    IMAGE_UPDATE_FAILURE,
    IMAGE_UPDATE_REQUEST,
    IMAGE_DELETE_SUCCESS,
    IMAGE_DELETE_FAILURE,
    IMAGE_DELETE_REQUEST
} from "../actions/images";

const setGetImages = (state = { data: {} }, action) => {
    switch (action.type) {
        case IMAGES_GET_SUCCESS:
            return Object.assign({}, action);
        case IMAGES_GET_FAILURE:
            return Object.assign({}, action);
        case IMAGES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetImage = (state = { data: {} }, action) => {
    switch (action.type) {
        case IMAGE_GET_SUCCESS:
            return Object.assign({}, action);
        case IMAGE_GET_FAILURE:
            return Object.assign({}, action);
        case IMAGE_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setCreateImage = (state = { created: false }, action) => {
    switch (action.type) {
        case IMAGE_CREATE_SUCCESS:
            return Object.assign({}, action);
        case IMAGE_CREATE_FAILURE:
            return Object.assign({}, action);
        case IMAGE_CREATE_REQUEST:
            return Object.assign({}, action);
        case IMAGE_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setUpdateImage = (state = { updated: false }, action) => {
    switch (action.type) {
        case IMAGE_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case IMAGE_UPDATE_FAILURE:
            return Object.assign({}, action);
        case IMAGE_UPDATE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteImage = (state = { deleted: false }, action) => {
    switch (action.type) {
        case IMAGE_DELETE_SUCCESS:
            return Object.assign({}, action);
        case IMAGE_DELETE_FAILURE:
            return Object.assign({}, action);
        case IMAGE_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetImages,
    setGetImage,
    setCreateImage,
    setUpdateImage,
    setDeleteImage
};