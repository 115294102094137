import toastr from 'toastr';
import Service from "../../../utility/Service"

export const CREATE_TAX_SUCCESS = "CREATE_TAX_SUCCESS";
export const CREATE_TAX_FAILURE = "CREATE_TAX_FAILURE";
export const CREATE_TAX_REQUEST = "CREATE_TAX_REQUEST";
export const CREATE_TAX_RESET = "CREATE_TAX_RESET";


export const taxCreateFailure = () => ({
    type: CREATE_TAX_FAILURE,
    loading: true,
    created: false,
    taxCreatedData: []
});

export const taxCreateSuccess = (data, pageNo, pageSize) => ({
    type: CREATE_TAX_SUCCESS,
    loading: false,
    created: true,
    taxCreatedData: data

});

export const taxCreateRequest = () => ({
    type: CREATE_TAX_REQUEST,
    loading: true,
    created: false,
    taxUpdatedData: []
});

export const taxCreateReset = () => ({
    type: CREATE_TAX_RESET,
    loading: false,
    created: false,
    taxUpdatedData: []
});

export const createtax = (values, pageNo, pageSize) => (dispatch, getState) => {
   // console.log("tax create values", values);
    toastr.remove()
    dispatch(taxCreateRequest())
    let url = "tax"
    // if (pageNo && pageSize) {
    //     url = "payment/charges?offset=0&limit=" + 50 + "?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.post(url, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
               // console.log(response);
                let data = {
                    data: response.data,
                    pagination: response.pagination
                }
                dispatch(taxCreateSuccess(data));
                dispatch(taxCreateReset());

            } else {
               // console.log("taxes", response);
                dispatch(taxCreateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





