import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const PLANS_GET_SUCCESS = "PLANS_GET_SUCCESS";
export const PLANS_GET_FAILURE = "PLANS_GET_FAILURE";
export const PLANS_GET_REQUEST = "PLANS_GET_REQUEST";
export const PLAN_GET_SUCCESS = "PLAN_GET_SUCCESS";
export const PLAN_GET_FAILURE = "PLAN_GET_FAILURE";
export const PLAN_GET_REQUEST = "PLAN_GET_REQUEST";
export const PLAN_UPDATE_SUCCESS = "PLAN_UPDATE_SUCCESS";
export const PLAN_UPDATE_FAILURE = "PLAN_UPDATE_FAILURE";
export const PLAN_UPDATE_REQUEST = "PLAN_UPDATE_REQUEST";
export const PLAN_UPDATE_RESET = "PLAN_UPDATE_RESET";
export const PLAN_CREATE_SUCCESS = "PLAN_CREATE_SUCCESS";
export const PLAN_CREATE_FAILURE = "PLAN_CREATE_FAILURE";
export const PLAN_CREATE_REQUEST = "PLAN_CREATE_REQUEST";
export const PLAN_CREATE_RESET = "PLAN_CREATE_RESET";
export const PLAN_DELETE_SUCCESS = "PLAN_DELETE_SUCCESS";
export const PLAN_DELETE_FAILURE = "PLAN_DELETE_FAILURE";
export const PLAN_DELETE_REQUEST = "PLAN_DELETE_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';

/**
 * All plans Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const plansGetRequest = () => ({
    type: PLANS_GET_REQUEST,
    loading: true,
    planData: []
});
/**
 * All plans Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const plansGetSuccess = (data, pageNo, pageSize) => ({
    type: PLANS_GET_SUCCESS,
    loading: false,
    planData: data
});
/**
 * All plans Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const plansGetFailure = () => ({
    type: PLANS_GET_FAILURE,
    loading: false,
    planData: []
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getplans = (pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(plansGetRequest())
    let url = "plan?offset=0&limit=500"
    Service.get(url,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    plans: response.data,
                    pagination: response.pagination
                }
                dispatch(plansGetSuccess(data));
            } else {
                console.log("plans", response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


/**
 * Single plan Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const planGetRequest = () => ({
    type: PLAN_GET_REQUEST,
    planData: []
});
/**
 * Single plan Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const planGetSuccess = (data) => ({
    type: PLAN_GET_SUCCESS,
    planData: data
});
/**
 * Single plan Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const planGetFailure = () => ({
    type: PLAN_GET_FAILURE,
    planData: []
});

/**
 * Get Single Plan
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getplan = (planId) => (dispatch, getState) => {
    toastr.remove()
    dispatch(planGetRequest())
    Service.get("plan/" + planId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.plansReceivedSuccessfully');
                console.log(response);
                dispatch(planGetSuccess(response.data));
            } else {
                console.log(response);
                dispatch(planGetFailure())
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

/**
 * Single plan Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const planCreateRequest = (data) => ({
    type: PLAN_CREATE_REQUEST,
    loading: true,
    created: false

});

export const planCreateSuccess = (data) => ({
    type: PLAN_CREATE_SUCCESS,
    loading: false,
    created: true

});

export const planCreateFailure = () => ({
    type: PLAN_CREATE_FAILURE,
    loading: false,
    created: false

});

export const planCreateReset = () => ({
    type: PLAN_CREATE_RESET,
    loading: false,
    created: false

});

export const createPlan = (values) => (dispatch, getState) => {
    toastr.remove()
    console.log(values);
    dispatch(planCreateRequest());
    Service.post("plan", values,
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success(response.message ? response.message : 'Locale.actions.plansReceivedSuccessfully');
                console.log(response);
                dispatch(planCreateSuccess());
                dispatch(planCreateReset());

            } else {
                console.log(response);
                dispatch(planCreateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



/**
 * Update Plan
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */

export const planUpdateReset = (data) => ({
    type: PLAN_UPDATE_RESET,
    loading: true,
    updated: false

});
export const planUpdateRequest = (data) => ({
    type: PLAN_UPDATE_REQUEST,
    loading: true,
    updated: false

});

export const planUpdateSuccess = (data) => ({
    type: PLAN_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const planUpdateFailure = (data) => ({
    type: PLAN_UPDATE_FAILURE,
    loading: false,
    updated: false

});



export const updatePlan = (planId, values) => (dispatch, getState) => {
    toastr.remove()
    console.log(planId, values);
    dispatch(planUpdateRequest());
    Service.patch("plan/" + planId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.plansReceivedSuccessfully');
                console.log(response);
                dispatch(planUpdateSuccess());
                dispatch(planUpdateReset());
                // dispatch(planGetSuccess(response.data.value));
            } else {
                console.log(response);
                dispatch(planUpdateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single plan Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const planDeleteRequest = (data) => ({
    type: PLAN_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single plan Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const planDeleteSuccess = (data) => ({
    type: PLAN_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single plan Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const planDeleteFailure = () => ({
    type: PLAN_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single Plan
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deleteplan = (planId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(planId);
    toastr.remove()
    dispatch(planDeleteRequest());
    // setTimeout(() => {
    //     // dispatch(planDeleteSuccess());
    //     dispatch(getplans(pageNo, pageSize));
    // }, 1000)

    Service.delete("plan/" + planId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Use');
                console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getplans(pageNo, pageSize));
            } else {
                console.log(response);
                dispatch(planDeleteFailure());
                toastr.error(response?.message || 'Something Went Wrong');
            }
        }
    )
};
