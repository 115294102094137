import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const SUPPORTS_GET_SUCCESS = "SUPPORTS_GET_SUCCESS";
export const SUPPORTS_GET_FAILURE = "SUPPORTS_GET_FAILURE";
export const SUPPORTS_GET_REQUEST = "SUPPORTS_GET_REQUEST";
export const SUPPORT_GET_SUCCESS = "SUPPORT_GET_SUCCESS";
export const SUPPORT_GET_FAILURE = "SUPPORT_GET_FAILURE";
export const SUPPORT_GET_REQUEST = "SUPPORT_GET_REQUEST";
export const SUPPORT_UPDATE_SUCCESS = "SUPPORT_UPDATE_SUCCESS";
export const SUPPORT_UPDATE_FAILURE = "SUPPORT_UPDATE_FAILURE";
export const SUPPORT_UPDATE_REQUEST = "SUPPORT_UPDATE_REQUEST";
export const SUPPORT_UPDATE_RESET = "SUPPORT_UPDATE_RESET";
export const SUPPORT_CREATE_SUCCESS = "SUPPORT_CREATE_SUCCESS";
export const SUPPORT_CREATE_FAILURE = "SUPPORT_CREATE_FAILURE";
export const SUPPORT_CREATE_REQUEST = "SUPPORT_CREATE_REQUEST";
export const SUPPORT_CREATE_RESET = "SUPPORT_CREATE_RESET";
export const SUPPORT_DELETE_SUCCESS = "SUPPORT_DELETE_SUCCESS";
export const SUPPORT_DELETE_FAILURE = "SUPPORT_DELETE_FAILURE";
export const SUPPORT_DELETE_REQUEST = "SUPPORT_DELETE_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';

/**
 * All plans Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportsGetRequest = () => ({
    type: SUPPORTS_GET_REQUEST,
    loading: true,
    supportData: []
});
/**
 * All plans Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportsGetSuccess = (data, pageNo, pageSize) => ({
    type: SUPPORTS_GET_SUCCESS,
    loading: false,
    supportData: data
});
/**
 * All plans Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportsGetFailure = () => ({
    type: SUPPORTS_GET_FAILURE,
    loading: false,
    supportData: []
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getsupports = (pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(supportsGetRequest())
    let url = "support?offset=0&limit=500"
    Service.get(url,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    support: response.data,
                    pagination: response.pagination
                }
                dispatch(supportsGetSuccess(data));
            } else {
                console.log("supports", response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


/**
 * Single plan Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportGetRequest = () => ({
    type: SUPPORT_GET_REQUEST,
    supportData: []
});
/**
 * Single plan Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportGetSuccess = (data) => ({
    type: SUPPORT_GET_SUCCESS,
    supportData: data
});
/**
 * Single plan Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportGetFailure = () => ({
    type: SUPPORT_GET_FAILURE,
    supportData: []
});

/**
 * Get Single Plan
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getsupport = (planId) => (dispatch, getState) => {
    toastr.remove()
    dispatch(supportGetRequest())
    Service.get("support/" + planId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.plansReceivedSuccessfully');
                console.log(response);
                dispatch(supportGetSuccess(response.data));
            } else {
                console.log(response);
                dispatch(supportGetFailure())
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

/**
 * Single plan Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportCreateRequest = (data) => ({
    type: SUPPORT_CREATE_REQUEST,
    loading: true,
    created: false

});

export const supportCreateSuccess = (data) => ({
    type: SUPPORT_CREATE_SUCCESS,
    loading: false,
    created: true

});

export const supportCreateFailure = () => ({
    type: SUPPORT_CREATE_FAILURE,
    loading: false,
    created: false

});

export const supportCreateReset = () => ({
    type: SUPPORT_CREATE_RESET,
    loading: false,
    created: false

});

export const createSupport = (values) => (dispatch, getState) => {
    toastr.remove()
    console.log(values);
    dispatch(supportCreateRequest());
    Service.post("support", values,
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success(response.message ? response.message : 'Locale.actions.plansReceivedSuccessfully');
                console.log(response);
                dispatch(supportCreateSuccess());
                dispatch(supportCreateReset());

            } else {
                console.log(response);
                dispatch(supportCreateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



/**
 * Update Plan
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */

export const supportUpdateReset = (data) => ({
    type: SUPPORT_UPDATE_RESET,
    loading: true,
    updated: false

});
export const supportUpdateRequest = (data) => ({
    type: SUPPORT_UPDATE_REQUEST,
    loading: true,
    updated: false

});

export const supportUpdateSuccess = (data) => ({
    type: SUPPORT_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const supportUpdateFailure = (data) => ({
    type: SUPPORT_UPDATE_FAILURE,
    loading: false,
    updated: false

});



export const updateSupport = (planId, values) => (dispatch, getState) => {
    toastr.remove()
    console.log(planId, values);
    dispatch(supportUpdateRequest());
    Service.patch("support/" + planId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.plansReceivedSuccessfully');
                console.log(response);
                dispatch(supportUpdateSuccess());
                dispatch(supportUpdateReset());
                // dispatch(planGetSuccess(response.data.value));
            } else {
                console.log(response);
                dispatch(supportUpdateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single plan Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportDeleteRequest = (data) => ({
    type: SUPPORT_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single plan Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportDeleteSuccess = (data) => ({
    type: SUPPORT_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single plan Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const supportDeleteFailure = () => ({
    type: SUPPORT_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single Plan
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deletesupport = (planId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(planId);
    toastr.remove()
    dispatch(supportDeleteRequest());
    // setTimeout(() => {
    //     // dispatch(planDeleteSuccess());
    //     dispatch(getplans(pageNo, pageSize));
    // }, 1000)

    Service.delete("support/" + planId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Use');
                console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getsupports(pageNo, pageSize));
            } else {
                console.log(response);
                dispatch(supportDeleteFailure());
                toastr.error(response?.message || 'Something Went Wrong');
            }
        }
    )
};
