import {

    CREATE_TAX_SUCCESS,
    CREATE_TAX_FAILURE,
    CREATE_TAX_REQUEST,
    CREATE_TAX_RESET,
}

    from '../actions/create';




const setCreateTax = (state = { created: false }, action) => {
    switch (action.type) {
        case CREATE_TAX_SUCCESS:
            return Object.assign({}, action);
        case CREATE_TAX_FAILURE:
            return Object.assign({}, action);
        case CREATE_TAX_REQUEST:
            return Object.assign({}, action);
        case CREATE_TAX_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setCreateTax
}