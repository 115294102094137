import {
    PAYMENTS_GET_SUCCESS,
    PAYMENTS_GET_FAILURE,
    PAYMENTS_GET_REQUEST
} from "../actions/payments";


const setGetPayments = (state = { paymentData: {} }, action) => {
    switch (action.type) {
        case PAYMENTS_GET_SUCCESS:
            return Object.assign({}, action);
        case PAYMENTS_GET_FAILURE:
            return Object.assign({}, action);
        case PAYMENTS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetPayments,

};