import {
    CONFIGS_GET_SUCCESS,
    CONFIGS_GET_FAILURE,
    CONFIGS_GET_REQUEST,
    CONFIG_GET_SUCCESS,
    CONFIG_GET_FAILURE,
    CONFIG_GET_REQUEST,
    CONFIG_DELETE_SUCCESS,
    CONFIG_DELETE_FAILURE,
    CONFIG_DELETE_REQUEST,
    CONFIG_CREATE_SUCCESS,
    CONFIG_CREATE_FAILURE,
    CONFIG_CREATE_REQUEST,
    CONFIG_CREATE_RESET,
    CONFIG_UPDATE_REQUEST,
    CONFIG_UPDATE_SUCCESS,
    CONFIG_UPDATE_RESET,
    CONFIG_UPDATE_FAILURE
} from "../actions/config";

const setGetConfigs = (state = { data: {} }, action) => {
    switch (action.type) {
        case CONFIGS_GET_SUCCESS:
            return Object.assign({}, action);
        case CONFIGS_GET_FAILURE:
            return Object.assign({}, action);
        case CONFIGS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetConfig = (state = { data: {} }, action) => {
    switch (action.type) {
        case CONFIG_GET_SUCCESS:
            return Object.assign({}, action);
        case CONFIG_GET_FAILURE:
            return Object.assign({}, action);
        case CONFIG_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreateConfig = (state = { created: false }, action) => {
    switch (action.type) {
        case CONFIG_CREATE_SUCCESS:
            return Object.assign({}, action);
        case CONFIG_CREATE_FAILURE:
            return Object.assign({}, action);
        case CONFIG_CREATE_REQUEST:
            return Object.assign({}, action);
        case CONFIG_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteConfig = (state = { deleted: false }, action) => {
    switch (action.type) {
        case CONFIG_DELETE_SUCCESS:
            return Object.assign({}, action);
        case CONFIG_DELETE_FAILURE:
            return Object.assign({}, action);
        case CONFIG_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateConfig = (state = { updated: false }, action) => {
    switch (action.type) {
        case CONFIG_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case CONFIG_UPDATE_FAILURE:
            return Object.assign({}, action);
        case CONFIG_UPDATE_REQUEST:
            return Object.assign({}, action);
        case CONFIG_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetConfigs,
    setGetConfig,
    setDeleteConfig,
    setCreateConfig,
    setUpdateConfig
};
