import { 
    CITIES_GET_SUCCESS,
    CITIES_GET_FAILURE,
    CITIES_GET_REQUEST,
    CITY_GET_SUCCESS,
    CITY_GET_FAILURE,
    CITY_GET_REQUEST,
    CITY_DELETE_SUCCESS,
    CITY_DELETE_FAILURE,
    CITY_DELETE_REQUEST
} from "../actions/cities";

const setGetCities = (state = { data: {} }, action) => {
    switch (action.type) {
        case CITIES_GET_SUCCESS:
            return Object.assign({}, action);
        case CITIES_GET_FAILURE:
            return Object.assign({}, action);
        case CITIES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetCity = (state = { data: {} }, action) => {
    switch (action.type) {
        case CITY_GET_SUCCESS:
            return Object.assign({}, action);
        case CITY_GET_FAILURE:
            return Object.assign({}, action);
        case CITY_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteCity = (state = { deleted: false }, action) => {
    switch (action.type) {
        case CITY_DELETE_SUCCESS:
            return Object.assign({}, action);
        case CITY_DELETE_FAILURE:
            return Object.assign({}, action);
        case CITY_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetCities,
    setGetCity,
    setDeleteCity
};