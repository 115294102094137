import {
    COUNTRIES_GET_SUCCESS,
    COUNTRIES_GET_FAILURE,
    COUNTRIES_GET_REQUEST,
    COUNTRY_GET_SUCCESS,
    COUNTRY_GET_FAILURE,
    COUNTRY_GET_REQUEST,
    COUNTRY_UPDATE_SUCCESS,
    COUNTRY_UPDATE_FAILURE,
    COUNTRY_UPDATE_REQUEST,
    COUNTRY_UPDATE_RESET,
    COUNTRY_CREATE_SUCCESS,
    COUNTRY_CREATE_FAILURE,
    COUNTRY_CREATE_REQUEST,
    COUNTRY_CREATE_RESET,
    COUNTRY_DELETE_SUCCESS,
    COUNTRY_DELETE_FAILURE,
    COUNTRY_DELETE_REQUEST,
    COUNTIES_GET_SUCCESS,
    COUNTIES_GET_FAILURE,
    COUNTIES_GET_REQUEST,
    ZIPCODE_GET_SUCCESS,
    ZIPCODE_GET_FAILURE,
    ZIPCODE_GET_REQUEST,
    CITYSTATE_GET_SUCCESS,
    CITYSTATE_GET_FAILURE,
    CITYSTATE_GET_REQUEST
} from "../actions/countries";

const setGetCountries = (state = { data: {} }, action) => {
    switch (action.type) {
        case COUNTRIES_GET_SUCCESS:
            return Object.assign({}, action);
        case COUNTRIES_GET_FAILURE:
            return Object.assign({}, action);
        case COUNTRIES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetCountry = (state = { data: {} }, action) => {
    switch (action.type) {
        case COUNTRY_GET_SUCCESS:
            return Object.assign({}, action);
        case COUNTRY_GET_FAILURE:
            return Object.assign({}, action);
        case COUNTRY_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setGetZipcode = (state = { data: {} }, action) => {
    switch (action.type) {
        case ZIPCODE_GET_SUCCESS:
            return Object.assign({}, action);
        case ZIPCODE_GET_FAILURE:
            return Object.assign({}, action);
        case ZIPCODE_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setGetAllCounties = (state = { data: {} }, action) => {
    switch (action.type) {
        case COUNTIES_GET_SUCCESS:
            return Object.assign({}, action);
        case COUNTIES_GET_FAILURE:
            return Object.assign({}, action);
        case COUNTIES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetCityState = (state = { data: {} }, action) => {
    switch (action.type) {
        case CITYSTATE_GET_SUCCESS:
            return Object.assign({}, action);
        case CITYSTATE_GET_FAILURE:
            return Object.assign({}, action);
        case CITYSTATE_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateCountry = (state = { updated: false }, action) => {
    switch (action.type) {
        case COUNTRY_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case COUNTRY_UPDATE_FAILURE:
            return Object.assign({}, action);
        case COUNTRY_UPDATE_REQUEST:
            return Object.assign({}, action);
        case COUNTRY_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreateCountry = (state = { created: false }, action) => {
    switch (action.type) {
        case COUNTRY_CREATE_SUCCESS:
            return Object.assign({}, action);
        case COUNTRY_CREATE_FAILURE:
            return Object.assign({}, action);
        case COUNTRY_CREATE_REQUEST:
            return Object.assign({}, action);
        case COUNTRY_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setDeleteCountry = (state = { deleted: false }, action) => {
    switch (action.type) {
        case COUNTRY_DELETE_SUCCESS:
            return Object.assign({}, action);
        case COUNTRY_DELETE_FAILURE:
            return Object.assign({}, action);
        case COUNTRY_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetCountries,
    setGetCountry,
    setDeleteCountry,
    setCreateCountry,
    setUpdateCountry,
    setGetAllCounties,
    setGetZipcode,
    setGetCityState
};