import toastr from 'toastr';

import Service from "../../../utility/Service";

export const ORDERS_GET_SUCCESS = "ORDERS_GET_SUCCESS";
export const ORDERS_GET_FAILURE = "ORDERS_GET_SUCCESS";
export const ORDERS_GET_REQUEST = "ORDERS_GET_SUCCESS";



/**
 * All plans Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const ordersGetRequest = () => ({
    type: ORDERS_GET_REQUEST,
    loading: true,
    pendingOrderData: []
});

/**
 * All plans Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const ordersGetSuccess = (data, pageNo, pageSize) => ({
    type: ORDERS_GET_SUCCESS,
    loading: false,
    pendingOrderData: data
});
/**
* All plans Failure
* @param  {[type]} data [description]
* @return {[type]}      [description]
*/
export const ordersGetFailure = () => ({
    type: ORDERS_GET_FAILURE,
    loading: false,
    pendingOrderData: []
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */

export const getpendingorders = (value, pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(ordersGetRequest())
    // let url = "order?offset=0&status=open&populate=all&limit=500"
    let url = "order/all/filter"
    // if (pageNo && pageSize) {
    // url = "order?offset=0&status=open&populate=all?page=" + pageNo + "&page_size=" + pageSize
    // }
    Service.post(url, value,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    orders: response.data,
                    pagination: response.pagination
                }
                dispatch(ordersGetSuccess(data));
            } else {
                console.log("pendingorders", response);
                dispatch(ordersGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

