import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

// export const USER_RESET = "USER_RESET";
export const ADMIN_GET_SUCCESS = "ADMIN_GET_SUCCESS";
export const ADMIN_GET_FAILURE = "ADMIN_GET_FAILURE";
export const ADMIN_GET_REQUEST = "ADMIN_GET_REQUEST";
export const ADMINS_GET_SUCCESS = "ADMINS_GET_SUCCESS";
export const ADMINS_GET_FAILURE = "ADMINS_GET_FAILURE";
export const ADMINS_GET_REQUEST = "ADMINS_GET_REQUEST";
export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
export const ADMIN_UPDATE_FAILURE = "ADMIN_UPDATE_FAILURE";
export const ADMIN_UPDATE_REQUEST = "ADMIN_UPDATE_REQUEST";
export const ADMIN_UPDATE_RESET = "ADMIN_UPDATE_RESET";

export const ADMIN_DELETE_SUCCESS = "ADMIN_DELETE_SUCCESS";
export const ADMIN_DELETE_FAILURE = "ADMIN_DELETE_FAILURE";
export const ADMIN_DELETE_REQUEST = "ADMIN_DELETE_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';


export const adminsGetRequest = () => ({
    type: ADMINS_GET_REQUEST,
    loading: true,
    userData: []
});

export const adminsGetSuccess = (data, pageNo, pageSize) => ({
    type: ADMINS_GET_SUCCESS,
    loading: false,
    userData: data
});

export const adminsGetFailure = () => ({
    type: ADMINS_GET_FAILURE,
    loading: false,
    userData: []
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getadmins = (pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    toastr.remove()
    dispatch(adminsGetRequest())
    let value = {
        "filter": {
            role: "admin"
        },
        "offset": 0,
        "limit": 1000

    }
    dispatch(adminsGetRequest());

    Service.post("user/all/filter?", value,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    users: response.data,
                    pagination: response.pagination
                }
                dispatch(adminsGetSuccess(data));
            } else {
               // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


// export const userRequest = () => ({
//     type: USER_RESET,
//     userData: {}
// });

export const adminGetRequest = () => ({
    type: ADMIN_GET_REQUEST,
    loading: true,
    userData: {}
});

export const adminGetSuccess = (data) => ({
    type: ADMIN_GET_SUCCESS,
    loading: false,
    userData: data
});

export const adminGetFailure = (data) => ({
    type: ADMIN_GET_FAILURE,
    loading: false,
    userData: {}
});

/**
 * Get Single User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getadmin = (userId) => (dispatch, getState) => {
    toastr.remove()
    // dispatch(userRequest());
    dispatch(adminGetRequest())
    Service.get("user/" + userId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
              //  console.log(response);
                dispatch(adminGetSuccess(response.data));
            } else {
               // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


export const adminUpdateRequest = () => ({
    type: ADMIN_UPDATE_REQUEST,
    loading: true,
    userData: [],
    updated: false
});

export const adminUpdateSuccess = (data) => ({
    type: ADMIN_UPDATE_SUCCESS,
    loading: false,
    userData: data,
    updated: true

});

export const adminUpdateFailure = () => ({
    type: ADMIN_UPDATE_FAILURE,
    loading: false,
    userData: [],
    updated: false

});

export const adminUpdateReset = () => ({
    type: ADMIN_UPDATE_RESET,
    loading: false,
    userData: [],
    updated: false

});

/**
 * Update User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateAdmin = (userId, values) => (dispatch, getState) => {
    toastr.remove()
   // console.log(userId, values);
    dispatch(adminUpdateRequest())
    Service.patch("user/" + userId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
              //  console.log(response);
                dispatch(adminUpdateSuccess());
                dispatch(adminUpdateReset());

            } else {
               // console.log(response);
                dispatch(adminUpdateFailure(response));

                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single user Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const adminDeleteRequest = (data) => ({
    type: ADMIN_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single user Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const adminDeleteSuccess = (data) => ({
    type: ADMIN_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single user Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const adminDeleteFailure = (data) => ({
    type: ADMIN_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deleteadmin = (userId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(userId);
    toastr.remove()
    dispatch(adminDeleteRequest());
    setTimeout(() => {
        // dispatch(userDeleteSuccess());
        dispatch(getadmins(pageNo, pageSize));




    }, 1000)

    // Service.delete("users/"+userId, {},
    //     (response) => {
    //         if (response && response.status && response.status === 200) {
    //             toastr.success(response.message ? response.message : 'Successfully Deleted the Use');
    //             console.log(response);
    //             // dispatch(userDeleteSuccess(response.data.value));
    //             dispatch(getusers(pageNo, pageSize));
    //         } else {
    //             console.log(response);
    //             // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
    //             toastr.error('Something Went Wrong');
    //         }
    //     }
    // )
};
