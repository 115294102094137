import toastr from 'toastr';

import { login as authLogin } from '../middleware/Authentication';
import Service from "../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const LOGIN_REQUEST = "LOGIN_REQUEST";

// import Toaster from '../views/notifications/toaster/Toaster';

/**
 * Login Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
 export const loginRequest = (data) => ({
    type: LOGIN_REQUEST,
    userData: data
});
/**
 * Login Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const loginSuccess = (data) => ({
    type: LOGIN_SUCCESS,
    userData: data
});
/**
 * Login Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const loginFailure = (data) => ({
    type: LOGIN_FAILURE,
    userData: data
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const login = (fields) => (dispatch, getState) => {
    toastr.remove()
    Service.post("auth/login", fields,
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success(response.message ? response.message : 'Logged In');
                authLogin(response.data)
                dispatch(loginSuccess(response.data));
            } else {
                toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response && response.message ? response.message : 'Something Went Wrong');
            }
        }
    )
};
