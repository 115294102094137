import toastr from 'toastr';

import Service from "../../../utility/Service"
import { ServiceUpdateSuccess } from 'src/views/services/actions/services';

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const COUNTRY_GET_SUCCESS = "COUNTRY_GET_SUCCESS";
export const COUNTRY_GET_FAILURE = "COUNTRY_GET_FAILURE";
export const COUNTRY_GET_REQUEST = "COUNTRY_GET_REQUEST";
export const COUNTRY_UPDATE_SUCCESS = "COUNTRY_UPDATE_SUCCESS";
export const COUNTRY_UPDATE_FAILURE = "COUNTRY_UPDATE_FAILURE";
export const COUNTRY_UPDATE_REQUEST = "COUNTRY_UPDATE_REQUEST";
export const COUNTRY_UPDATE_RESET = "COUNTRYU_UPDATE_REQUEST";
export const COUNTRY_CREATE_SUCCESS = "COUNTRY_CREATE_SUCCESS";
export const COUNTRY_CREATE_FAILURE = "COUNTRY_CREATE_FAILURE";
export const COUNTRY_CREATE_REQUEST = "COUNTRY_CREATE_REQUEST";
export const COUNTRY_CREATE_RESET = "COUNTRYU_CREATE_REQUEST";
export const COUNTRY_DELETE_SUCCESS = "COUNTRY_DELETE_SUCCESS";
export const COUNTRY_DELETE_FAILURE = "COUNTRY_DELETE_FAILURE";
export const COUNTRY_DELETE_REQUEST = "COUNTRY_DELETE_REQUEST";
export const COUNTRIES_GET_REQUEST = "COUNTRIES_GET_REQUEST";
export const COUNTRIES_GET_SUCCESS = "COUNTRIES_GET_SUCCESS";
export const COUNTRIES_GET_FAILURE = "COUNTRIES_GET_FAILURE";
export const CITYSTATE_GET_REQUEST = "CITYSTATE_GET_REQUEST";
export const CITYSTATE_GET_SUCCESS = "CITYSTATE_GET_SUCCESS";
export const CITYSTATE_GET_FAILURE = "CITYSTATE_GET_FAILURE";


export const COUNTIES_GET_SUCCESS = "COUNTIES_GET_SUCCESS";
export const COUNTIES_GET_FAILURE = "COUNTIES_GET_FAILURE";
export const COUNTIES_GET_REQUEST = "COUNTIES_GET_REQUEST";

export const ZIPCODE_GET_SUCCESS = "ZIPCODE_GET_SUCCESS";
export const ZIPCODE_GET_FAILURE = "ZIPCODE_GET_FAILURE";
export const ZIPCODE_GET_REQUEST = "ZIPCODE_GET_REQUEST";

// import Toaster from '../views/notifications/toaster/Toaster';

/**
 * All counties Success
    * @param  { [type] } data[description]
        * @return { [type]}[description]
            * /
export const countriesGetSuccess = (data, pageNo, pageSize) => ({
    type: COUNTRIES_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */


/**
 * All services Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const citystateGetRequest = () => ({
    type: CITYSTATE_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * All services Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const citystateGetSuccess = (data, pageNo, pageSize) => ({
    type: CITYSTATE_GET_SUCCESS,
    loading: false,
    data: data
});

export const citystateGetFailure = () => ({
    type: CITYSTATE_GET_FAILURE,
    loading: false,
    data: []
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */

export const getcitystate = (zip, pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    toastr.remove()
    dispatch(citystateGetRequest())
    Service.get("zip-county/zipcode/" + zip,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    counties: response.data,
                    pagination: response.pagination
                }
                dispatch(citystateGetSuccess(data));
            } else {
                console.log(response);
                dispatch(citystateGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



/**
 * All counties Success
    * @param  { [type] } data[description]
        * @return { [type]}[description]
            * /
export const countriesGetSuccess = (data, pageNo, pageSize) => ({
    type: COUNTRIES_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */


/**
 * All services Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countiesGetRequest = () => ({
    type: COUNTIES_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * All services Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countiesGetSuccess = (data, pageNo, pageSize) => ({
    type: COUNTIES_GET_SUCCESS,
    loading: false,
    data: data
});

export const countiesGetFailure = () => ({
    type: COUNTIES_GET_FAILURE,
    loading: false,
    data: []
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */

export const getcounties = (pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    toastr.remove()
    dispatch(countiesGetRequest())
    Service.get("counties?offset=0&limit=500",
        // ? page = " + pageNo + " & page_size=" + pageSize,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    counties: response.data,
                    pagination: response.pagination
                }
                dispatch(countiesGetSuccess(data));
            } else {
                console.log(response);
                dispatch(countiesGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

/**
 * All counties Success
    * @param  { [type] } data[description]
        * @return { [type]}[description]
            * /
export const countriesGetSuccess = (data, pageNo, pageSize) => ({
    type: COUNTRIES_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */


/**
 * All services Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const zipcodeGetRequest = () => ({
    type: ZIPCODE_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * All services Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const zipcodeGetSuccess = (data, pageNo, pageSize) => ({
    type: ZIPCODE_GET_SUCCESS,
    loading: false,
    data: data
});

export const zipcodeGetFailure = () => ({
    type: ZIPCODE_GET_FAILURE,
    loading: false,
    data: []
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */

export const getzipcode = (county, pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    toastr.remove()
    dispatch(zipcodeGetRequest())
    Service.get("zip-county/county/" + county,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    zipcode: response.data,
                    pagination: response.pagination
                }
                dispatch(zipcodeGetSuccess(data));
            } else {
                console.log(response);
                dispatch(zipcodeGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

/**
 * All services Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const servicesGetRequest = () => ({
    type: COUNTRIES_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * All services Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countriesGetSuccess = (data, pageNo, pageSize) => ({
    type: COUNTRIES_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceserviceGetFailure = () => ({
    type: COUNTRIES_GET_FAILURE,
    loading: false,
    data: []
});



export const getcountries = (values,pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    console.log("value county", values);
    toastr.remove()
    if(values){
    dispatch(servicesGetRequest())
    Service.post("zip-county/filter?",values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    services: response.data,
                    pagination: response.pagination
                }
                dispatch(countriesGetSuccess(data));
            } else {
                console.log(response);
                dispatch(serviceserviceGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
    }else{
        let value ={
            "filter": {
                //"county":"Abbeville",
                //"state": "SC"
            },
            "sort":{
               // "zipcode":1
            },
            "limit": 1000,
            "offset":0
         }
    dispatch(servicesGetRequest())
    Service.post("zip-county/filter",value,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    services: response.data,
                    pagination: response.pagination
                }
                dispatch(countriesGetSuccess(data));
            } else {
                console.log(response);
                dispatch(serviceserviceGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
    }
};




/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countryGetRequest = () => ({
    type: COUNTRY_GET_REQUEST,
    loading: false,
    data: []
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countryGetSuccess = (data) => ({
    type: COUNTRY_GET_SUCCESS,
    loading: true,
    data: data
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countryGetFailure = (data) => ({
    type: COUNTRY_GET_FAILURE,
    loading: false,
    data: []
});

/**
 * Get Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getcountry = (serviceId) => (dispatch, getState) => {
    toastr.remove()
    dispatch(countryGetRequest());
    Service.get("zip-county/" + serviceId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(countryGetSuccess(response.data));
            } else {
                console.log(response);
                dispatch(countryGetFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

export const countyCreateRequest = () => ({
    type: COUNTRY_CREATE_REQUEST,
    created: false

});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countyCreateSuccess = (data) => ({
    type: COUNTRY_CREATE_SUCCESS,
    data: data,
    created: true

});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countyCreateFailure = () => ({
    type: COUNTRY_CREATE_FAILURE,
    created: false,
    data: []
});

export const countyCreateReset = (data) => ({
    type: COUNTRY_CREATE_RESET,
    created: false,
    data: []
});

/**
 * Create Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const createCountry = (values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(countyCreateRequest());
    Service.post("zip-county", values,
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(countyCreateSuccess(response));
                dispatch(countyCreateReset());
            } else {
                console.log(response);
                dispatch(countyCreateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

export const countyUpdateSuccess = (data) => ({
    type: COUNTRY_UPDATE_SUCCESS,
    data: data,
    updated: true

});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const countyUpdateFailure = () => ({
    type: COUNTRY_UPDATE_FAILURE,
    updated: false,
    data: []
});

export const countyUpdateReset = () => ({
    type: COUNTRY_UPDATE_RESET,
    updated: false,
    data: []
});

export const countyUpdateRequest = () => ({
    type: COUNTRY_UPDATE_REQUEST,
    updated: false,
    data: []
});


/**
 * Update Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateCountry = (serviceId, values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(countyUpdateRequest());

    Service.patch("zip-county/" + serviceId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(countyUpdateSuccess(response));
                dispatch(countyUpdateReset());

            } else {
                console.log(response);
                dispatch(countyUpdateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteRequest = (data) => ({
    type: COUNTRY_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteSuccess = (data) => ({
    type: COUNTRY_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteFailure = () => ({
    type: COUNTRY_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deletecountry = (serviceId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(serviceId);
    toastr.remove()
    dispatch(serviceDeleteRequest());
    Service.delete("zip-county/" + serviceId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Use');
                console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getcountries(pageNo, pageSize));
            } else {
                console.log(response);
                dispatch(serviceDeleteFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};