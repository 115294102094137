import jwt_decode from "jwt-decode";
const TOKEN_KEY = 'auth';

// LOGIN
export const login = (data) => {
    // if (d.username === 'user' && d.password === 'password') {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(data.results.access_token))
    // props.history.push('/home');
    // }
}

// LOGOUT
export const logout = () => localStorage.removeItem(TOKEN_KEY)

// LOGIN STATUS
export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) return true;
    return false;
}

export const getAuthData = () => {
    return localStorage.getItem(TOKEN_KEY);
}

export const getToken = () => {
    var authData = getAuthData();
    let accessToken = ""
    if (authData) {
        // var decoded = jwt_decode(authData.jwt);
        // console.log(decoded);
        accessToken = authData.access_token
    }
    return accessToken
}

export const isTokenExpired = () => {
    let token = getToken()
    if (token) {
        let decoded = jwt_decode(token)
        if (decoded.exp < Date.now() / 1000) {
            return true;
        }
    }
    return false;
}   