import { 
    VEHICLEMODELS_GET_SUCCESS,
    VEHICLEMODELS_GET_FAILURE,
    VEHICLEMODELS_GET_REQUEST,
    VEHICLEMODEL_GET_SUCCESS,
    VEHICLEMODEL_GET_FAILURE,
    VEHICLEMODEL_GET_REQUEST,
    VEHICLEMODEL_DELETE_SUCCESS,
    VEHICLEMODEL_DELETE_FAILURE,
    VEHICLEMODEL_DELETE_REQUEST,
    VEHICLEMODEL_CREATE_SUCCESS,
    VEHICLEMODEL_CREATE_FAILURE,
    VEHICLEMODEL_CREATE_REQUEST,
    VEHICLEMODEL_CREATE_RESET,
    VEHICLEMODEL_UPDATE_SUCCESS,
    VEHICLEMODEL_UPDATE_FAILURE,
    VEHICLEMODEL_UPDATE_REQUEST,
    VEHICLEMODEL_UPDATE_RESET
    
} from "../actions/models";

const setGetVehicleModels = (state = { data: {} }, action) => {
    switch (action.type) {
        case VEHICLEMODELS_GET_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMODELS_GET_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMODELS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetVehicleModel = (state = { data: {} }, action) => {
    switch (action.type) {
        case VEHICLEMODEL_GET_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMODEL_GET_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMODEL_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteVehicleModels = (state = { deleted: false }, action) => {
    switch (action.type) {
        case VEHICLEMODEL_DELETE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMODEL_DELETE_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMODEL_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreateVehicleModel = (state = { created: false }, action) => {
    switch (action.type) {
        case VEHICLEMODEL_CREATE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMODEL_CREATE_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMODEL_CREATE_REQUEST:
            return Object.assign({}, action);
        case VEHICLEMODEL_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateVehicleModel = (state = { updated: false }, action) => {
    switch (action.type) {
        case VEHICLEMODEL_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLEMODEL_UPDATE_FAILURE:
            return Object.assign({}, action);
        case VEHICLEMODEL_UPDATE_REQUEST:
            return Object.assign({}, action);
        case VEHICLEMODEL_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetVehicleModels,
    setGetVehicleModel,
    setDeleteVehicleModels,
    setCreateVehicleModel,
    setUpdateVehicleModel
};