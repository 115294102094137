import {
    TAX_DELETE_SUCCESS,
    TAX_DELETE_FAILURE,
    TAX_DELETE_REQUEST
}
    from '../actions/deletetax'


const setDeleteTax = (state = { deleted: false }, action) => {
    switch (action.type) {
        case TAX_DELETE_SUCCESS:
            return Object.assign({}, action);
        case TAX_DELETE_FAILURE:
            return Object.assign({}, action);
        case TAX_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {

    setDeleteTax
}