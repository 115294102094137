import {
  ORDERS_GET_SUCCESS,
  ORDERS_GET_FAILURE,
  ORDERS_GET_REQUEST,

} from "../actions/completeorders";

const setGetCompletedOrders = (state = { completedOrderData: {} }, action) => {
  switch (action.type) {
      case ORDERS_GET_SUCCESS:
          return Object.assign({}, action);
      case ORDERS_GET_FAILURE:
          return Object.assign({}, action);
      case ORDERS_GET_REQUEST:
          return Object.assign({}, action);
      default:
          return state;
  }
};

export {
  setGetCompletedOrders
}
