// import Request from "sync-request";
import axios from 'axios';
// import envData from "../../public/assets/env.json"

class Env {

    constructor() {
        this.env = null;
    }

    /**
     * To fetch the env values
     *
     * @returns {json} Fetch the content of env.json and set env variable
     */
    fetchEnv() {
        let url = window.location.origin + "/assets/env.json";
        axios({method: 'get', url: url}, {
            headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate"
            }
        }).then(response => {
            this.env = response.data;
        });
    }

    /**
     * Response parser
     *
     * @returns {mixed} parse the response
     */
    responseParser() {
        return (response) => {
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else if (contentType && ((contentType.indexOf("application/pdf") !== -1) || (contentType.indexOf("application/csv") !== -1) || (contentType.indexOf("text/csv") !== -1) || (contentType.indexOf("application/ms-excel") !== -1))) {
                return response.blob();
            } else {
                return response.text();
            }
        }
    }

    /**
     * To set the env values
     *
     * @return json
     */
    setEnv() {
        return this.env;
    }

    /**
     * Get the value based on key
     *
     * @return string
     */
    getEnv(key) {
        let value = this.setEnv();
        return value ? value[key] : '';
    }

    getEnvironment() {
        return this.env;
    }

    setEnvironment(env) {
        this.env = env;
    }
}
export default new Env();
