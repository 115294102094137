import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const CITY_GET_SUCCESS = "CITY_GET_SUCCESS";
export const CITY_GET_FAILURE = "CITY_GET_FAILURE";
export const CITY_GET_REQUEST = "CITY_GET_REQUEST";
export const CITIES_GET_SUCCESS = "CITIES_GET_SUCCESS";
export const CITIES_GET_FAILURE = "CITIES_GET_FAILURE";
export const CITIES_GET_REQUEST = "CITIES_GET_REQUEST";
export const CITY_DELETE_SUCCESS = "CITY_DELETE_SUCCESS";
export const CITY_DELETE_FAILURE = "CITY_DELETE_FAILURE";
export const CITY_DELETE_REQUEST = "CITY_DELETE_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';


 export const citiesGetRequest = () => ({
    type: CITIES_GET_REQUEST,
    loading: true,
    data: []
});

export const citiesGetSuccess = (data, pageNo, pageSize) => ({
    type: CITIES_GET_SUCCESS,
    loading: false,
    data: data
});

export const citiesGetFailure = () => ({
    type: CITIES_GET_FAILURE,
    loading: false,
    data: []
});


export const getcities = (pageNo = 0, pageSize=10) => (dispatch, getState) => {
    toastr.remove()
    dispatch(citiesGetRequest())
    Service.get("admin/cities?page="+ pageNo + "&page_size=" + pageSize,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    cities : response.data,
                    pagination : response.pagination
                }
                dispatch(citiesGetSuccess(data));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



 export const cityGetRequest = (data) => ({
    type: CITY_GET_REQUEST,
    data: data
});

export const cityGetSuccess = (data) => ({
    type: CITY_GET_SUCCESS,
    data: data
});

export const cityGetFailure = (data) => ({
    type: CITY_GET_FAILURE,
    data: data
});

/**
 * Get Single City
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const getcity = (serviceId) => (dispatch, getState) => {
    toastr.remove()
    Service.get("admin/countries/"+serviceId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(cityGetSuccess(response.data));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



/**
 * Create City
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const createCountry = (values) => (dispatch, getState) => {
    toastr.remove()
    Service.post("admin/countries", values,
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(cityGetSuccess(response.data.value));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



/**
 * Update Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const updateService = (serviceId, values) => (dispatch, getState) => {
    toastr.remove()
    Service.put("admin/services/"+serviceId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                // dispatch(cityGetSuccess(response.data.value));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
 export const serviceDeleteRequest = (data) => ({
    type: CITY_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteSuccess = (data) => ({
    type: CITY_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteFailure = () => ({
    type: CITY_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const deletecountry = (serviceId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(serviceId);
    toastr.remove()
    dispatch(serviceDeleteRequest());
    Service.delete("admin/services/"+serviceId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Use');
                console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getcities(pageNo, pageSize));
            } else {
                console.log(response);
                dispatch(serviceDeleteFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};