import { combineReducers } from "redux";
import { LOGIN_SUCCESS, LOGIN_FAILURE, FORGOT_PASSWORD } from "../actions/auth";
// import { UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE } from "../actions/common";

// Reducers
import {
    setGetUsers,
    setGetUser,
    setUpdateUser,
    setDeleteUser
} from "../views/users/reducers/users"
import {
    setGetMechanics,
    setGetMechanic,
    setUpdateMechanic,
    setDeleteMechanic
} from "../views/users/reducers/mechanics"
import {
    setGetAdmins,
    setGetAdmin,
    setUpdateAdmin,
    setDeleteAdmin
} from "../views/users/reducers/admins"
import {
    setGetRoles,
    setGetRole,
    setCreateRole,
    setUpdateRole,
    setDeleteRole,
    setGetPermissions
} from "../views/roles/reducers/roles"
import {
    setGetServices,
    setGetService,
    setDeleteService,
    setCreateService,
    setUpdateService
} from "../views/services/reducers/services"
import {
    setGetPlans,
    setGetPlan,
    setDeletePlan,
    setCreatePlan,
    setUpdatePlan
} from "../views/plans/reducers/plans"
import {
    setGetCountries,
    setCreateCountry,
    setUpdateCountry,
    setGetCountry,
    setGetZipcode,
    setGetAllCounties,
    setDeleteCountry,
    setGetCityState
} from "../views/countries/reducers/countries"
import {
    setGetCities,
    setGetCity,
    setDeleteCity
} from "../views/cities/reducers/cities"
import {
    setGetVehicleTypes,
    setGetVehicleType,
    setDeleteVehicleTypes,
    setCreateVehicleType,
    setUpdateVehicleType
} from "../views/vehicles/reducers/vehicles"
import {
    setGetVehicleMakes,
    setGetVehicleMake,
    setDeleteVehicleMakes,
    setCreateVehicleMake,
    setUpdateVehicleMake
} from "../views/vehicles/reducers/makes"

import {
    setGetVehicleModels,
    setGetVehicleModel,
    setDeleteVehicleModels,
    setCreateVehicleModel,
    setUpdateVehicleModel
} from "../views/vehicles/reducers/models"


import {
    setGetImages,
    setGetImage,
    setCreateImage,
    setUpdateImage,
    setDeleteImage
} from "../views/images/reducers/images"


import {
    setGetPayments,

} from "../views/payments/reducers/payments"

import {
    setGetOrders

} from "../views/orders/reducers/orders"

import {
    setGetPendingOrders
} from "../views/orders/reducers/pendingorders"

import {
    setGetCompletedOrders
} from "../views/orders/reducers/completeorder"

import {
    setGetCancelledOrders
} from "../views/orders/reducers/cancelledorders"

import {
    setGetPendingPayments,
    setGetPendingDetailsPayments,

} from "../views/payments/reducers/pendingpayments"
import {
    setGetCompletePayments,

} from "../views/payments/reducers/completepayments"

import {
    setGetTaxes

} from "../views/tax/reducers/totaltax"

import {
    setGetTax

} from "../views/tax/reducers/tax"

import {
    setUpdateTax
} from "../views/tax/reducers/edittax"

import {
    setDeleteTax
} from "../views/tax/reducers/deletetax"


import {
    setCreateTax
} from "../views/tax/reducers/create"

import {
    setGetTotalPayments,
    setGetTotalOrders,
    setGetTotalUsers
} from "../views/dashboard/reducers/dashboard"

import {
    setGetConfigs,
    setGetConfig,
    setCreateConfig,
    setUpdateConfig,
    setDeleteConfig
} from "../views/config/reducers/config"


import {
    setGetSupports,
    setGetSupport,
    setCreateSupport,
    setUpdateSupport,
    setDeleteSupport
} from "../views/support/reducers/support"

// import { from } from "core-js/core/array";

const initialState = {
    sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}

const setLogin = (state = { userData: {} }, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return Object.assign({}, action);
        case LOGIN_FAILURE:
            return Object.assign({}, action);
        case FORGOT_PASSWORD:
            return Object.assign({}, action);
        default:
            return state;
    }
};


// export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
// export const UPDATE_FAILURE = "UPDATE_FAILURE";
// export const UPDATE_REQUEST = "UPDATE_REQUEST";

// const updateStatus = (state = { updated: false }, action) => {
//     switch (action.type) {
//         case UPDATE_SUCCESS:
//             return Object.assign({}, action);
//         case UPDATE_FAILURE:
//             return Object.assign({}, action);
//         case UPDATE_REQUEST:
//             return Object.assign({}, action);
//         default:
//             return state;
//     }
// };

const rootReducer = combineReducers({
    changeState,
    // Login
    setLogin,
    // Users
    setGetUsers,
    setGetUser,
    setUpdateUser,
    setDeleteUser,
    //Mechanics
    setGetMechanics,
    setGetMechanic,
    setUpdateMechanic,
    setDeleteMechanic,
    //Admins
    setGetAdmins,
    setGetAdmin,
    setUpdateAdmin,
    setDeleteAdmin,
    // Generic
    // updateStatus,
    // Roles
    setGetRoles,
    setGetRole,
    setCreateRole,
    setUpdateRole,
    setDeleteRole,
    setGetPermissions,
    // Services
    setGetServices,
    setGetService,
    setDeleteService,
    setCreateService,
    setUpdateService,
    // Plans
    setGetPlans,
    setGetPlan,
    setCreatePlan,
    setUpdatePlan,
    setDeletePlan,
    // Countries
    setGetCountries,
    setGetCountry,
    setUpdateCountry,
    setCreateCountry,
    setGetZipcode,
    setGetAllCounties,
    setGetCityState,
    setDeleteCountry,
    // Cities
    setGetCities,
    setGetCity,
    setDeleteCity,
    // Images
    setGetImages,
    setGetImage,
    setCreateImage,
    setUpdateImage,
    setDeleteImage,
    // Vehicles
    setGetVehicleTypes,
    setGetVehicleType,
    setDeleteVehicleTypes,
    setCreateVehicleType,
    setUpdateVehicleType,
    // Vehicles Makes
    setGetVehicleMakes,
    setGetVehicleMake,
    setDeleteVehicleMakes,
    setCreateVehicleMake,
    setUpdateVehicleMake,
    // Vehicles Models
    setGetVehicleModels,
    setGetVehicleModel,
    setDeleteVehicleModels,
    setCreateVehicleModel,
    setUpdateVehicleModel,

    //payments
    setGetPayments,
    setGetPendingPayments,
    setGetPendingDetailsPayments,
    setGetCompletePayments,

    //orders
    setGetOrders,
    setGetPendingOrders,
    setGetCompletedOrders,
    setGetCancelledOrders,


    //taxes
    setGetTaxes,
    setGetTax,
    setUpdateTax,
    setCreateTax,
    setDeleteTax,

    //dashboard

    setGetTotalPayments,
    setGetTotalOrders,
    setGetTotalUsers,

    //config
    setGetConfigs,
    setGetConfig,
    setCreateConfig,
    setUpdateConfig,
    setDeleteConfig,

    //support

    setGetSupports,
    setGetSupport,
    setCreateSupport,
    setUpdateSupport,
    setDeleteSupport

});

export default rootReducer;
