import {
    TOTAL_ORDERS_GET_SUCCESS,
    TOTAL_ORDERS_GET_FAILURE,
    TOTAL_ORDERS_GET_REQUEST,

    TOTAL_PAYMENTS_GET_SUCCESS,
    TOTAL_PAYMENTS_GET_FAILURE,
    TOTAL_PAYMENTS_GET_REQUEST,

    TOTAL_USERS_GET_SUCCESS,
    TOTAL_USERS_GET_FAILURE,
    TOTAL_USERS_GET_REQUEST,

} from "../actions/dashboard";

const setGetTotalUsers = (state = { data: {} }, action) => {
    switch (action.type) {
        case TOTAL_USERS_GET_SUCCESS:
            return Object.assign({}, action);
        case TOTAL_USERS_GET_FAILURE:
            return Object.assign({}, action);
        case TOTAL_USERS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};



const setGetTotalPayments = (state = { data: {} }, action) => {
    switch (action.type) {
        case TOTAL_PAYMENTS_GET_SUCCESS:
            return Object.assign({}, action);
        case TOTAL_PAYMENTS_GET_FAILURE:
            return Object.assign({}, action);
        case TOTAL_PAYMENTS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setGetTotalOrders = (state = { data: {} }, action) => {
    switch (action.type) {
        case TOTAL_ORDERS_GET_SUCCESS:
            return Object.assign({}, action);
        case TOTAL_ORDERS_GET_FAILURE:
            return Object.assign({}, action);
        case TOTAL_ORDERS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


export {
    setGetTotalOrders,
    setGetTotalPayments,
    setGetTotalUsers
};