import { 
    VEHICLETYPES_GET_SUCCESS,
    VEHICLETYPES_GET_FAILURE,
    VEHICLETYPES_GET_REQUEST,
    VEHICLETYPE_GET_SUCCESS,
    VEHICLETYPE_GET_FAILURE,
    VEHICLETYPE_GET_REQUEST,
    VEHICLETYPE_DELETE_SUCCESS,
    VEHICLETYPE_DELETE_FAILURE,
    VEHICLETYPE_DELETE_REQUEST,
    VEHICLETYPE_CREATE_SUCCESS,
    VEHICLETYPE_CREATE_FAILURE,
    VEHICLETYPE_CREATE_REQUEST,
    VEHICLETYPE_CREATE_RESET,
    VEHICLETYPE_UPDATE_SUCCESS,
    VEHICLETYPE_UPDATE_FAILURE,
    VEHICLETYPE_UPDATE_REQUEST,
    VEHICLETYPE_UPDATE_RESET
    
} from "../actions/vehicles";

const setGetVehicleTypes = (state = { data: {} }, action) => {
    switch (action.type) {
        case VEHICLETYPES_GET_SUCCESS:
            return Object.assign({}, action);
        case VEHICLETYPES_GET_FAILURE:
            return Object.assign({}, action);
        case VEHICLETYPES_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetVehicleType = (state = { data: {} }, action) => {
    switch (action.type) {
        case VEHICLETYPE_GET_SUCCESS:
            return Object.assign({}, action);
        case VEHICLETYPE_GET_FAILURE:
            return Object.assign({}, action);
        case VEHICLETYPE_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteVehicleTypes = (state = { deleted: false }, action) => {
    switch (action.type) {
        case VEHICLETYPE_DELETE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLETYPE_DELETE_FAILURE:
            return Object.assign({}, action);
        case VEHICLETYPE_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreateVehicleType = (state = { created: false }, action) => {
    switch (action.type) {
        case VEHICLETYPE_CREATE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLETYPE_CREATE_FAILURE:
            return Object.assign({}, action);
        case VEHICLETYPE_CREATE_REQUEST:
            return Object.assign({}, action);
        case VEHICLETYPE_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateVehicleType = (state = { updated: false }, action) => {
    switch (action.type) {
        case VEHICLETYPE_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case VEHICLETYPE_UPDATE_FAILURE:
            return Object.assign({}, action);
        case VEHICLETYPE_UPDATE_REQUEST:
            return Object.assign({}, action);
        case VEHICLETYPE_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetVehicleTypes,
    setGetVehicleType,
    setDeleteVehicleTypes,
    setCreateVehicleType,
    setUpdateVehicleType
};