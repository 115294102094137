import {
    PLANS_GET_SUCCESS,
    PLANS_GET_FAILURE,
    PLANS_GET_REQUEST,
    PLAN_GET_SUCCESS,
    PLAN_GET_FAILURE,
    PLAN_GET_REQUEST,
    PLAN_UPDATE_SUCCESS,
    PLAN_UPDATE_FAILURE,
    PLAN_UPDATE_REQUEST,
    PLAN_UPDATE_RESET,
    PLAN_CREATE_SUCCESS,
    PLAN_CREATE_FAILURE,
    PLAN_CREATE_REQUEST,
    PLAN_CREATE_RESET,
    PLAN_DELETE_SUCCESS,
    PLAN_DELETE_FAILURE,
    PLAN_DELETE_REQUEST
} from "../actions/plans";

const setGetPlans = (state = { planData: {} }, action) => {
    switch (action.type) {
        case PLANS_GET_SUCCESS:
            return Object.assign({}, action);
        case PLANS_GET_FAILURE:
            return Object.assign({}, action);
        case PLANS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setGetPlan = (state = { planData: {} }, action) => {
    switch (action.type) {
        case PLAN_GET_SUCCESS:
            return Object.assign({}, action);
        case PLAN_GET_FAILURE:
            return Object.assign({}, action);
        case PLAN_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreatePlan = (state = { planData: {} }, action) => {
    switch (action.type) {
        case PLAN_CREATE_SUCCESS:
            return Object.assign({}, action);
        case PLAN_CREATE_FAILURE:
            return Object.assign({}, action);
        case PLAN_CREATE_REQUEST:
            return Object.assign({}, action);
        case PLAN_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdatePlan = (state = { planData: {} }, action) => {
    switch (action.type) {
        case PLAN_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case PLAN_UPDATE_FAILURE:
            return Object.assign({}, action);
        case PLAN_UPDATE_REQUEST:
            return Object.assign({}, action);
        case PLAN_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeletePlan = (state = { deleted: false }, action) => {
    switch (action.type) {
        case PLAN_DELETE_SUCCESS:
            return Object.assign({}, action);
        case PLAN_DELETE_FAILURE:
            return Object.assign({}, action);
        case PLAN_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetPlans,
    setGetPlan,
    setDeletePlan,
    setCreatePlan,
    setUpdatePlan
};