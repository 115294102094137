import {
    TOTALTAX_GET_SUCCESS,
    TOTALTAX_GET_FAILURE,
    TOTALTAX_GET_REQUEST
} from "../actions/totaltax";


const setGetTaxes = (state = { taxesData: {} }, action) => {
    switch (action.type) {
        case TOTALTAX_GET_SUCCESS:
            return Object.assign({}, action);
        case TOTALTAX_GET_FAILURE:
            return Object.assign({}, action);
        case TOTALTAX_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetTaxes,

};