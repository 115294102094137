import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

// export const USER_RESET = "USER_RESET";
export const MECHANIC_GET_SUCCESS = "MECHANIC_GET_SUCCESS";
export const MECHANIC_GET_FAILURE = "MECHANIC_GET_FAILURE";
export const MECHANIC_GET_REQUEST = "MECHANIC_GET_REQUEST";
export const MECHANICS_GET_SUCCESS = "MECHANICS_GET_SUCCESS";
export const MECHANICS_GET_FAILURE = "MECHANICS_GET_FAILURE";
export const MECHANICS_GET_REQUEST = "MECHANICS_GET_REQUEST";
export const MECHANIC_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const MECHANIC_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const MECHANIC_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const MECHANIC_UPDATE_RESET = "USER_UPDATE_RESET";
export const MECHANIC_DELETE_SUCCESS = "MECHANIC_DELETE_SUCCESS";
export const MECHANIC_DELETE_FAILURE = "MECHANIC_DELETE_FAILURE";
export const MECHANIC_DELETE_REQUEST = "MECHANIC_DELETE_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';


export const mechanicsGetRequest = () => ({
    type: MECHANICS_GET_SUCCESS,
    loading: true,
    userData: []
});

export const mechanicsGetSuccess = (data, pageNo, pageSize) => ({
    type: MECHANICS_GET_SUCCESS,
    loading: false,
    userData: data
});

export const mechanicsGetFailure = () => ({
    type: MECHANICS_GET_FAILURE,
    loading: false,
    userData: []
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getmechanics = (value, pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    toastr.remove()
    dispatch(mechanicsGetRequest())
    if (value) {

        dispatch(mechanicsGetRequest());

        Service.post("user/all/filter?", value,
            (response) => {
                if (response && response.status && response.status === 200) {
                    // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                    // console.log(response);
                    let data = {
                        users: response.data,
                        pagination: response.pagination
                    }
                    dispatch(mechanicsGetSuccess(data));
                } else {
                  //  console.log(response);
                    // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                    toastr.error('Something Went Wrong');
                }
            }
        )


    }
    else {
        let value = {
            "filter": {
                role: "mechanic"
            },
            "offset": 0,
            "limit": 1000

        }
        dispatch(mechanicsGetRequest());

        Service.post("user/all/filter?", value,
            (response) => {
                if (response && response.status && response.status === 200) {
                    // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                    // console.log(response);
                    let data = {
                        users: response.data,
                        pagination: response.pagination
                    }
                    dispatch(mechanicsGetSuccess(data));
                } else {
                   // console.log(response);
                    // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                    toastr.error('Something Went Wrong');
                }
            }
        )

    }
};


// export const userRequest = () => ({
//     type: USER_RESET,
//     userData: {}
// });

export const mechanicGetRequest = () => ({
    type: MECHANIC_GET_REQUEST,
    loading: true,
    userData: []
});

export const mechanicGetSuccess = (data) => ({
    type: MECHANIC_GET_SUCCESS,
    loading: false,
    userData: data
});

export const mechanicGetFailure = () => ({
    type: MECHANIC_GET_FAILURE,
    loading: false,
    userData: []
});

/**
 * Get Single User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getmechanic = (userId) => (dispatch, getState) => {
    toastr.remove()
    // dispatch(userRequest());
    dispatch(mechanicGetRequest())
    Service.get("user/" + userId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
             //   console.log(response);
                dispatch(mechanicGetSuccess(response.data));
            } else {
             //   console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


export const mechanicUpdateRequest = () => ({
    type: MECHANIC_UPDATE_REQUEST,
    loading: true,
});

export const mechanicUpdateSuccess = (data) => ({
    type: MECHANIC_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const mechanicUpdateFailure = () => ({
    type: MECHANIC_UPDATE_FAILURE,
    loading: false,
});


export const mechanicUpdateReset = () => ({
    type: MECHANIC_UPDATE_RESET,
    loading: true,
});


export const updateMechanic = (userId, values) => (dispatch, getState) => {
    toastr.remove()
   // console.log(userId, values);
    dispatch(mechanicUpdateRequest())
    Service.patch("user/" + userId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
              //  console.log(response);
                dispatch(mechanicUpdateSuccess(response));
                dispatch(mechanicUpdateReset());

                // dispatch(userGetSuccess(response.data.value));
            } else {
               // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single user Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const mechanicDeleteRequest = (data) => ({
    type: MECHANIC_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single user Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const mechanicDeleteSuccess = (data) => ({
    type: MECHANIC_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single user Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const mechanicDeleteFailure = (data) => ({
    type: MECHANIC_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deletemechanic = (userId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(userId);
    toastr.remove()
    dispatch(mechanicDeleteRequest());
    // setTimeout(() => {
    //     // dispatch(userDeleteSuccess());
    //     dispatch(getmechanics(pageNo, pageSize));




    // }, 1000)

    Service.delete("user/"+userId, {},
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Use');
              //  console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getmechanics(pageNo, pageSize));
            } else {
              //  console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};
