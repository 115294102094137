import {
    // USER_RESET,
    ADMINS_GET_SUCCESS,
    ADMINS_GET_FAILURE,
    ADMINS_GET_REQUEST,
    ADMIN_GET_SUCCESS,
    ADMIN_GET_FAILURE,
    ADMIN_GET_REQUEST,
    ADMIN_UPDATE_SUCCESS,
    ADMIN_UPDATE_FAILURE,
    ADMIN_UPDATE_RESET,
    ADMIN_UPDATE_REQUEST,
    ADMIN_DELETE_SUCCESS,
    ADMIN_DELETE_FAILURE,
    ADMIN_DELETE_REQUEST,

} from "../actions/admins";

const setGetAdmins = (state = { userData: {} }, action) => {
    switch (action.type) {
        case ADMINS_GET_SUCCESS:
            return Object.assign({}, action);
        case ADMINS_GET_FAILURE:
            return Object.assign({}, action);
        case ADMINS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetAdmin = (state = { userData: {} }, action) => {
    switch (action.type) {
        // case USER_RESET:
        //     return Object.assign({}, action);
        case ADMIN_GET_SUCCESS:
            return Object.assign({}, action);
        case ADMIN_GET_FAILURE:
            return Object.assign({}, action);
        case ADMIN_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateAdmin = (state = { updated: false }, action) => {
    switch (action.type) {
        // case USER_RESET:
        //     return Object.assign({}, action);
        case ADMIN_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case ADMIN_UPDATE_FAILURE:
            return Object.assign({}, action);
        case ADMIN_UPDATE_REQUEST:
            return Object.assign({}, action);
        case ADMIN_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteAdmin = (state = { deleted: false }, action) => {
    switch (action.type) {
        case ADMIN_DELETE_SUCCESS:
            return Object.assign({}, action);
        case ADMIN_DELETE_FAILURE:
            return Object.assign({}, action);
        case ADMIN_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetAdmins,
    setGetAdmin,
    setUpdateAdmin,
    setDeleteAdmin
};
