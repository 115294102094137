import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const IMAGE_GET_SUCCESS = "IMAGE_GET_SUCCESS";
export const IMAGE_GET_FAILURE = "IMAGE_GET_FAILURE";
export const IMAGE_GET_REQUEST = "IMAGE_GET_REQUEST";
export const IMAGE_CREATE_SUCCESS = "IMAGE_CREATE_SUCCESS";
export const IMAGE_CREATE_FAILURE = "IMAGE_CREATE_FAILURE";
export const IMAGE_CREATE_REQUEST = "IMAGE_CREATE_REQUEST";
export const IMAGE_CREATE_RESET = "IMAGE_CREATE_RESET";
export const IMAGES_GET_SUCCESS = "IMAGES_GET_SUCCESS";
export const IMAGES_GET_FAILURE = "IMAGES_GET_FAILURE";
export const IMAGES_GET_REQUEST = "IMAGES_GET_REQUEST";
export const IMAGE_UPDATE_SUCCESS = "IMAGE_UPDATE_SUCCESS";
export const IMAGE_UPDATE_FAILURE = "IMAGE_UPDATE_FAILURE";
export const IMAGE_UPDATE_REQUEST = "IMAGE_UPDATE_REQUEST";
export const IMAGE_DELETE_SUCCESS = "IMAGE_DELETE_SUCCESS";
export const IMAGE_DELETE_FAILURE = "IMAGE_DELETE_FAILURE";
export const IMAGE_DELETE_REQUEST = "IMAGE_DELETE_REQUEST";

// import Toaster from '../views/notifications/toaster/Toaster';


 export const imagesGetRequest = () => ({
    type: IMAGES_GET_REQUEST,
    loading: true,
    data: []
});

export const imagesGetSuccess = (data, pageNo, pageSize) => ({
    type: IMAGES_GET_SUCCESS,
    loading: false,
    data: data
});

export const imagesGetFailure = () => ({
    type: IMAGES_GET_FAILURE,
    loading: false,
    data: []
});

/**
 * Roles
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getimages = (pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    let url = "admin/images"
    if(pageNo && pageSize) {
        url = "admin/images?page="+ pageNo + "&page_size=" + pageSize
    }
    dispatch(imagesGetRequest())
    Service.get(url,
        (response) => {
            if (response && response.status && response.status === 200) {
                let data = {
                    images : response.data,
                    pagination : response.pagination
                }
                dispatch(imagesGetSuccess(data));
            } else {
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



export const uploadimage = (values) => (dispatch, getState) => {
    console.log(values)
    dispatch(imageCreateRequest())
    Service.post("admin/images", values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success(response.message ? response.message : 'Image uploaded successfully!');
                dispatch(imageCreateSuccess());
                dispatch(getimages())
            } else {
                dispatch(imageCreateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error(response.message ? response.message : 'Image upload Failed');
            }
        }
    )
};


export const imageGetRequest = () => ({
    type: IMAGE_GET_REQUEST,
    loading: true,
    data: {}
});

export const imageGetSuccess = (data) => ({
    type: IMAGE_GET_SUCCESS,
    loading: false,
    data: data
});

export const imageGetFailure = () => ({
    type: IMAGE_GET_FAILURE,
    loading: false,
    data: {}
});

/**
 * Get Single Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const getrole = (id) => (dispatch, getState) => {
    toastr.remove()
    dispatch(imageGetRequest());
    Service.get("admin/images/"+id,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                dispatch(imageGetSuccess(response.data));
            } else {
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





export const imageCreateReset = (data) => ({
    type: IMAGE_CREATE_RESET,
    loading: true
});
export const imageCreateRequest = (data) => ({
    type: IMAGE_CREATE_REQUEST,
    loading: true
});

export const imageCreateSuccess = (data) => ({
    type: IMAGE_CREATE_SUCCESS,
    loading: false,
    created: true
});

export const imageCreateFailure = (data) => ({
    type: IMAGE_CREATE_FAILURE,
    loading: false
});

/**
 * Create Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const createRole = (values) => (dispatch, getState) => {
    toastr.remove()
    console.log(values);
    dispatch(imageCreateRequest());
    Service.post("admin/images", values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('New Role is added Successfully');
                dispatch(imageCreateSuccess());
            } else {
                dispatch(imageCreateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.success(response.message ? response.message : 'Image upload Failed');
            }
        }
    )
};





export const imageUpdateRequest = (data) => ({
    type: IMAGE_UPDATE_REQUEST,
    loading: true
});

export const imageUpdateSuccess = (data) => ({
    type: IMAGE_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const imageUpdateFailure = (data) => ({
    type: IMAGE_UPDATE_FAILURE,
    loading: false
});


/**
 * Update Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const updateRole = (id, values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(imageUpdateRequest());
    Service.put("admin/images/"+id, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success('Role is updated successfully');
                // console.log(response);
                // dispatch(imageUpdateSuccess(response.data.value));
                // dispatch(getimages(1, 10));
            } else {
                // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.success('Updating Role is failed');
            }
        }
    )
};





export const roleDeleteRequest = () => ({
    type: IMAGE_DELETE_REQUEST,
    loading: true,
    deleted: false
});

export const roleDeleteSuccess = () => ({
    type: IMAGE_DELETE_SUCCESS,
    loading: false,
    deleted: true
});

export const roleDeleteFailure = () => ({
    type: IMAGE_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const deleteimage = (id, pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(roleDeleteRequest());
    Service.delete("admin/images/"+id, {},
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('Role is deleted successfully!');
                dispatch(getimages(pageNo, pageSize));
            } else {
                toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
            }
        }
    )
};
