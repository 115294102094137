import {
    // USER_RESET,
    MECHANICS_GET_SUCCESS,
    MECHANICS_GET_FAILURE,
    MECHANICS_GET_REQUEST,
    MECHANIC_GET_SUCCESS,
    MECHANIC_GET_FAILURE,
    MECHANIC_GET_REQUEST,
    MECHANIC_UPDATE_SUCCESS,
    MECHANIC_UPDATE_FAILURE,
    MECHANIC_UPDATE_REQUEST,
    MECHANIC_UPDATE_RESET,
    MECHANIC_DELETE_SUCCESS,
    MECHANIC_DELETE_FAILURE,
    MECHANIC_DELETE_REQUEST
} from "../actions/mechanics";

const setGetMechanics = (state = { userData: {} }, action) => {
    switch (action.type) {
        case MECHANICS_GET_SUCCESS:
            return Object.assign({}, action);
        case MECHANICS_GET_FAILURE:
            return Object.assign({}, action);
        case MECHANICS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setGetMechanic = (state = { userData: {} }, action) => {
    switch (action.type) {

        case MECHANIC_GET_SUCCESS:
            return Object.assign({}, action);
        case MECHANIC_GET_FAILURE:
            return Object.assign({}, action);
        case MECHANIC_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateMechanic = (state = { updated: false }, action) => {
    switch (action.type) {

        case MECHANIC_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case MECHANIC_UPDATE_FAILURE:
            return Object.assign({}, action);
        case MECHANIC_UPDATE_REQUEST:
            return Object.assign({}, action);
        case MECHANIC_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteMechanic = (state = { deleted: false }, action) => {
    switch (action.type) {
        case MECHANIC_DELETE_SUCCESS:
            return Object.assign({}, action);
        case MECHANIC_DELETE_FAILURE:
            return Object.assign({}, action);
        case MECHANIC_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetMechanics,
    setGetMechanic,
    setUpdateMechanic,
    setDeleteMechanic
};
