import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const CONFIG_GET_SUCCESS = "CONFIG_GET_SUCCESS";
export const CONFIG_GET_FAILURE = "CONFIG_GET_FAILURE";
export const CONFIG_GET_REQUEST = "CONFIG_GET_REQUEST";
export const CONFIGS_GET_SUCCESS = "CONFIGS_GET_SUCCESS";
export const CONFIGS_GET_FAILURE = "CONFIGS_GET_FAILURE";
export const CONFIGS_GET_REQUEST = "CONFIGS_GET_REQUEST";
export const CONFIG_DELETE_SUCCESS = "CONFIG_DELETE_SUCCESS";
export const CONFIG_DELETE_FAILURE = "CONFIG_DELETE_FAILURE";
export const CONFIG_DELETE_REQUEST = "CONFIG_DELETE_REQUEST";
export const CONFIG_CREATE_REQUEST = "CONFIG_CREATE_REQUEST";
export const CONFIG_CREATE_SUCCESS = "CONFIG_CREATE_SUCCESS";
export const CONFIG_CREATE_RESET = "CONFIG_CREATE_RESET";
export const CONFIG_CREATE_FAILURE = "CONFIG_CREATE_FAILURE";
export const CONFIG_UPDATE_REQUEST = "CONFIG_UPDATE_REQUEST";
export const CONFIG_UPDATE_SUCCESS = "CONFIG_UPDATE_SUCCESS";
export const CONFIG_UPDATE_FAILURE = "CONFIG_UPDATE_FAILURE";
export const CONFIG_UPDATE_RESET = "CONFIG_UPDATE_RESET";


// import Toaster from '../views/notifications/toaster/Toaster';

/**
 * All services Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configsGetRequest = () => ({
    type: CONFIGS_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * All services Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configsGetSuccess = (data, pageNo, pageSize) => ({
    type: CONFIGS_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * All services Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configsGetFailure = () => ({
    type: CONFIGS_GET_FAILURE,
    loading: false,
    data: []
});

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getconfigs = () => (dispatch) => {
    toastr.remove()
    dispatch(configsGetRequest())
    Service.get("/config?populate=plans?offset=0&limit=500",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    configs: response.data,
                    pagination: response.pagination
                }
                dispatch(configsGetSuccess(data));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configGetRequest = () => ({
    type: CONFIG_GET_REQUEST,
    loading: true,
    data: []
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configGetSuccess = (data) => ({
    type: CONFIG_GET_SUCCESS,
    loading: false,
    data: data
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configGetFailure = (data) => ({
    type: CONFIG_GET_FAILURE,
    loading: false,
    data: []
});

/**
 * Get Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getconfig = (serviceId) => (dispatch, getState) => {
    toastr.remove()
    dispatch(configGetRequest());
    Service.get("config/" + serviceId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(configGetSuccess(response.data));
            } else {
                console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};




export const createConfigRequest = () => ({
    type: CONFIG_CREATE_REQUEST,
    loading: true,
    created: false
});

export const createConfigSuccess = (data) => ({
    type: CONFIG_CREATE_SUCCESS,
    loading: false,
    created: true
});
export const createConfigReset = (data) => ({
    type: CONFIG_CREATE_RESET,
    loading: false,
    created: false
});
export const createConfigFailure = () => ({
    type: CONFIG_CREATE_FAILURE,
    loading: false
});

/**
 * Create Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const createConfig = (values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(createConfigRequest());
    Service.post("config", values,
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Created Successfully');
                console.log(response);
                dispatch(createConfigSuccess());
                dispatch(createConfigReset());
                // dispatch(serviceGetSuccess(response.data.value));
            } else {
                console.log(response);
                dispatch(createConfigFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};

export const ConfigUpdateReset = (data) => ({
    type: CONFIG_UPDATE_RESET,
    loading: true,
    updated: false

});
export const ConfigUpdateRequest = () => ({
    type: CONFIG_UPDATE_REQUEST,
    loading: true,
    updated: false

});

export const ConfigUpdateSuccess = (data) => ({
    type: CONFIG_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const ConfigUpdateFailure = () => ({
    type: CONFIG_UPDATE_FAILURE,
    loading: false,
    updated: false

});

/**
 * Update Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateConfig = (serviceId, values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(ConfigUpdateRequest());

    Service.patch("config/" + serviceId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
                console.log(response);
                dispatch(ConfigUpdateSuccess(response));
                dispatch(ConfigUpdateReset());
                // dispatch(serviceGetSuccess(response.data.value));
            } else {
                console.log(response);
                dispatch(ConfigUpdateFailure());

                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configDeleteRequest = (data) => ({
    type: CONFIG_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configDeleteSuccess = (data) => ({
    type: CONFIG_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const configDeleteFailure = () => ({
    type: CONFIG_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deleteconfig = (serviceId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(serviceId);
    toastr.remove()
    dispatch(configDeleteRequest());
    Service.delete("config/" + serviceId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Service');
                console.log(response);
                dispatch(configDeleteSuccess(response));
                dispatch(getconfigs());
            } else {
                dispatch(configDeleteFailure());
                toastr.error(response?.message || 'Something Went Wrong');
            }
        }
    )
};

