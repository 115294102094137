import toastr from 'toastr';

import Service from "../../../utility/Service"

// orders

export const TOTAL_ORDERS_GET_SUCCESS = "TOTAL_ORDERS_GET_SUCCESS";
export const TOTAL_ORDERS_GET_FAILURE = "TOTAL_ORDERS_GET_FAILURE";
export const TOTAL_ORDERS_GET_REQUEST = "TOTAL_ORDERS_GET_REQUEST";

//payments

export const TOTAL_PAYMENTS_GET_SUCCESS = "TOTAL_PAYMENTS_GET_SUCCESS";
export const TOTAL_PAYMENTS_GET_FAILURE = "TOTAL_PAYMENTS_GET_FAILURE";
export const TOTAL_PAYMENTS_GET_REQUEST = "TOTAL_PAYMENTS_GET_REQUEST";

//users

export const TOTAL_USERS_GET_SUCCESS = "TOTAL_USERS_GET_SUCCESS";
export const TOTAL_USERS_GET_FAILURE = "TOTAL_USERS_GET_FAILURE";
export const TOTAL_USERS_GET_REQUEST = "TOTAL_USERS_GET_REQUEST";

export const totalUsersGetRequest = () => ({
    type: TOTAL_USERS_GET_REQUEST,
    loading: true,
    data: []
});

export const totalUsersGetSuccess = (data, pageNo, pageSize) => ({
    type: TOTAL_USERS_GET_SUCCESS,
    loading: false,
    data: data
});

export const totalUsersGetFailure = () => ({
    type: TOTAL_USERS_GET_FAILURE,
    loading: false,
    data: []
});


export const getusersbystatus = (pageNo = 1, pageSize = 20) => (dispatch, getState) => {
    toastr.remove()
    dispatch(totalUsersGetRequest())
    Service.get("user/all/usergrpbyrole",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    usersbygroup: response.data
                }
                dispatch(totalUsersGetSuccess(data));
            } else {
                console.log(response);
                dispatch(totalUsersGetFailure(response));

                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


export const totalPaymentsGetRequest = () => ({
    type: TOTAL_PAYMENTS_GET_REQUEST,
    loading: true,
    data: []
});

export const totalPaymentsGetSuccess = (data, pageNo, pageSize) => ({
    type: TOTAL_PAYMENTS_GET_SUCCESS,
    loading: false,
    data: data
});

export const totalPaymentsGetFailure = () => ({
    type: TOTAL_PAYMENTS_GET_FAILURE,
    loading: false,
    data: []
});


export const getpaymentbystatus = (pageNo = 1, pageSize = 20) => (dispatch, getState) => {
    toastr.remove()
    dispatch(totalPaymentsGetRequest())
    Service.get("payment/getallpaymentsgrpbystatus",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    paymentbygroup: response.data
                }
                dispatch(totalPaymentsGetSuccess(data));
            } else {
                console.log(response);
                dispatch(totalPaymentsGetFailure(response));

                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


export const totalOrdersGetRequest = () => ({
    type: TOTAL_ORDERS_GET_REQUEST,
    loading: true,
    data: []
});

export const totalOrdersGetSuccess = (data, pageNo, pageSize) => ({
    type: TOTAL_ORDERS_GET_SUCCESS,
    loading: false,
    data: data
});

export const totalOrdersGetFailure = () => ({
    type: TOTAL_ORDERS_GET_FAILURE,
    loading: false,
    data: []
});


export const getorderbystatus = (pageNo = 1, pageSize = 20) => (dispatch, getState) => {
    toastr.remove()
    dispatch(totalOrdersGetRequest())
    Service.get("order/all/groupbystatus",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    orderbygroup: response.data
                }
                dispatch(totalOrdersGetSuccess(data));
            } else {
                console.log(response);
                dispatch(totalOrdersGetFailure(response));

                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









