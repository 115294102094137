import {
    SUPPORTS_GET_SUCCESS,
    SUPPORTS_GET_FAILURE,
    SUPPORTS_GET_REQUEST,
    SUPPORT_GET_SUCCESS,
    SUPPORT_GET_FAILURE,
    SUPPORT_GET_REQUEST,
    SUPPORT_UPDATE_SUCCESS,
    SUPPORT_UPDATE_FAILURE,
    SUPPORT_UPDATE_REQUEST,
    SUPPORT_UPDATE_RESET,
    SUPPORT_CREATE_SUCCESS,
    SUPPORT_CREATE_FAILURE,
    SUPPORT_CREATE_REQUEST,
    SUPPORT_CREATE_RESET,
    SUPPORT_DELETE_SUCCESS,
    SUPPORT_DELETE_FAILURE,
    SUPPORT_DELETE_REQUEST
} from "../actions/support";

const setGetSupports = (state = { supportData: {} }, action) => {
    switch (action.type) {
        case SUPPORTS_GET_SUCCESS:
            return Object.assign({}, action);
        case SUPPORTS_GET_FAILURE:
            return Object.assign({}, action);
        case SUPPORTS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setGetSupport = (state = { supportData: {} }, action) => {
    switch (action.type) {
        case SUPPORT_GET_SUCCESS:
            return Object.assign({}, action);
        case SUPPORT_GET_FAILURE:
            return Object.assign({}, action);
        case SUPPORT_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setCreateSupport = (state = { supportData: {} }, action) => {
    switch (action.type) {
        case SUPPORT_CREATE_SUCCESS:
            return Object.assign({}, action);
        case SUPPORT_CREATE_FAILURE:
            return Object.assign({}, action);
        case SUPPORT_CREATE_REQUEST:
            return Object.assign({}, action);
        case SUPPORT_CREATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};

const setUpdateSupport = (state = { supportData: {} }, action) => {
    switch (action.type) {
        case SUPPORT_UPDATE_SUCCESS:
            return Object.assign({}, action);
        case SUPPORT_UPDATE_FAILURE:
            return Object.assign({}, action);
        case SUPPORT_UPDATE_REQUEST:
            return Object.assign({}, action);
        case SUPPORT_UPDATE_RESET:
            return Object.assign({}, action);
        default:
            return state;
    }
};


const setDeleteSupport = (state = { deleted: false }, action) => {
    switch (action.type) {
        case SUPPORT_DELETE_SUCCESS:
            return Object.assign({}, action);
        case SUPPORT_DELETE_FAILURE:
            return Object.assign({}, action);
        case SUPPORT_DELETE_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetSupports,
    setGetSupport,
    setDeleteSupport,
    setCreateSupport,
    setUpdateSupport
};