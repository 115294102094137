import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const ROLE_GET_SUCCESS = "ROLE_GET_SUCCESS";
export const ROLE_GET_FAILURE = "ROLE_GET_FAILURE";
export const ROLE_GET_REQUEST = "ROLE_GET_REQUEST";
export const ROLE_CREATE_SUCCESS = "ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_FAILURE = "ROLE_CREATE_FAILURE";
export const ROLE_CREATE_REQUEST = "ROLE_CREATE_REQUEST";
export const ROLE_CREATE_RESET = "ROLE_CREATE_RESET";
export const ROLES_GET_SUCCESS = "ROLES_GET_SUCCESS";
export const ROLES_GET_FAILURE = "ROLES_GET_FAILURE";
export const ROLES_GET_REQUEST = "ROLES_GET_REQUEST";
export const ROLE_UPDATE_SUCCESS = "ROLE_UPDATE_SUCCESS";
export const ROLE_UPDATE_FAILURE = "ROLE_UPDATE_FAILURE";
export const ROLE_UPDATE_REQUEST = "ROLE_UPDATE_REQUEST";
export const ROLE_DELETE_SUCCESS = "ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_FAILURE = "ROLE_DELETE_FAILURE";
export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST";
export const PERMISSIONS_GET_SUCCESS = "PERMISSIONS_GET_SUCCESS";
export const PERMISSIONS_GET_FAILURE = "PERMISSIONS_GET_FAILURE";
export const PERMISSIONS_GET_REQUEST = "PERMISSIONS_GET_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';


 export const rolesGetRequest = () => ({
    type: ROLES_GET_REQUEST,
    loading: true,
    data: []
});

export const rolesGetSuccess = (data, pageNo, pageSize) => ({
    type: ROLES_GET_SUCCESS,
    loading: false,
    data: data
});

export const usersGetFailure = () => ({
    type: ROLES_GET_FAILURE,
    loading: false,
    data: []
});

/**
 * Roles
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getroles = (pageNo = 1, pageSize=10) => (dispatch, getState) => {
    toastr.remove()
    let url = "admin/roles"
    if(pageNo && pageSize) {
        url = "admin/roles?page="+ pageNo + "&page_size=" + pageSize
    }
    dispatch(rolesGetRequest())
    Service.get(url,
        (response) => {
            if (response && response.status && response.status === 200) {
                let data = {
                    roles : response.data,
                    pagination : response.pagination
                }
                dispatch(rolesGetSuccess(data));
            } else {
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



export const userGetRequest = () => ({
    type: ROLE_GET_REQUEST,
    loading: true,
    data: {}
});

export const roleGetSuccess = (data) => ({
    type: ROLE_GET_SUCCESS,
    loading: false,
    data: data
});

export const userGetFailure = () => ({
    type: ROLE_GET_FAILURE,
    loading: false,
    data: {}
});

/**
 * Get Single Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const getrole = (id) => (dispatch, getState) => {
    toastr.remove()
    dispatch(userGetRequest());
    Service.get("admin/roles/"+id,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                dispatch(roleGetSuccess(response.data));
            } else {
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};





export const roleCreateReset = (data) => ({
    type: ROLE_CREATE_RESET,
    loading: true
});
export const roleCreateRequest = (data) => ({
    type: ROLE_CREATE_REQUEST,
    loading: true
});

export const roleCreateSuccess = (data) => ({
    type: ROLE_CREATE_SUCCESS,
    loading: false,
    created: true
});

export const roleCreateFailure = (data) => ({
    type: ROLE_CREATE_FAILURE,
    loading: false
});

/**
 * Create Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const createRole = (values) => (dispatch, getState) => {
    toastr.remove()
    console.log(values);
    dispatch(roleCreateRequest());
    Service.post("admin/roles", values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('New Role is added Successfully');
                dispatch(roleCreateSuccess());
                dispatch(roleCreateReset());
            } else {
                dispatch(roleCreateFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Role creation is failed');
            }
        }
    )
};





export const roleUpdateRequest = (data) => ({
    type: ROLE_UPDATE_REQUEST,
    loading: true
});

export const roleUpdateSuccess = (data) => ({
    type: ROLE_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const roleUpdateFailure = (data) => ({
    type: ROLE_UPDATE_FAILURE,
    loading: false
});


/**
 * Update Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const updateRole = (id, values) => (dispatch, getState) => {
    toastr.remove()
    dispatch(roleUpdateRequest());
    Service.put("admin/roles/"+id, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success('Role is updated successfully');
                // console.log(response);
                // dispatch(roleUpdateSuccess(response.data.value));
                // dispatch(getroles(1, 10));
            } else {
                // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.success('Updating Role is failed');
            }
        }
    )
};





export const roleDeleteRequest = () => ({
    type: ROLE_DELETE_REQUEST,
    loading: true,
    deleted: false
});

export const roleDeleteSuccess = () => ({
    type: ROLE_DELETE_SUCCESS,
    loading: false,
    deleted: true
});

export const roleDeleteFailure = () => ({
    type: ROLE_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Role
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
 export const deleterole = (id, pageNo, pageSize) => (dispatch, getState) => {
    toastr.remove()
    dispatch(roleDeleteRequest());
    Service.delete("admin/roles/"+id, {},
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('Role is deleted successfully!');
                dispatch(getroles(pageNo, pageSize));
            } else {
                toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
            }
        }
    )
};





export const permissionsGetRequest = () => ({
    type: PERMISSIONS_GET_REQUEST,
    loading: true,
    data: []
});

export const permissionsGetSuccess = (data, pageNo, pageSize) => ({
    type: PERMISSIONS_GET_SUCCESS,
    loading: false,
    data: data
});

export const permissionsGetFailure = () => ({
    type: PERMISSIONS_GET_FAILURE,
    loading: false,
    data: []
});

/**
 * Get Permissions
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getpermissions = (pageNo = 0, pageSize=10) => (dispatch, getState) => {
    toastr.remove()
    let url = "admin/permissions"
    if(pageNo && pageSize) {
        url = "admin/permissions?page="+ pageNo + "&page_size=" + pageSize
    }
    dispatch(permissionsGetRequest())
    Service.get(url,
        (response) => {
            if (response && response.status && response.status === 200) {
                let data = {
                    permissions : response.data,
                    pagination : response.pagination
                }
                dispatch(permissionsGetSuccess(data));
            } else {
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};