import {
    COMPLETE_PAYMENTS_GET_SUCCESS,
    COMPLETE_PAYMENTS_GET_FAILURE,
    COMPLETE_PAYMENTS_GET_REQUEST
} from "../actions/completepayments";


const setGetCompletePayments = (state = { completepaymentData: {} }, action) => {
    switch (action.type) {
        case COMPLETE_PAYMENTS_GET_SUCCESS:
            return Object.assign({}, action);
        case COMPLETE_PAYMENTS_GET_FAILURE:
            return Object.assign({}, action);
        case COMPLETE_PAYMENTS_GET_REQUEST:
            return Object.assign({}, action);
        default:
            return state;
    }
};

export {
    setGetCompletePayments,
};