import toastr from 'toastr';

import Service from "../../../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

export const VEHICLETYPE_GET_SUCCESS = "VEHICLETYPE_GET_SUCCESS";
export const VEHICLETYPE_GET_FAILURE = "VEHICLETYPE_GET_FAILURE";
export const VEHICLETYPE_GET_REQUEST = "VEHICLETYPE_GET_REQUEST";
export const VEHICLETYPES_GET_SUCCESS = "VEHICLETYPES_GET_SUCCESS";
export const VEHICLETYPES_GET_FAILURE = "VEHICLETYPES_GET_FAILURE";
export const VEHICLETYPES_GET_REQUEST = "VEHICLETYPES_GET_REQUEST";
export const VEHICLETYPE_GET_RESET = "VEHICLETYPE_GET_RESET";
export const VEHICLETYPE_DELETE_SUCCESS = "VEHICLETYPE_DELETE_SUCCESS";
export const VEHICLETYPE_DELETE_FAILURE = "VEHICLETYPE_DELETE_FAILURE";
export const VEHICLETYPE_DELETE_REQUEST = "VEHICLETYPE_DELETE_REQUEST";
export const VEHICLETYPE_CREATE_SUCCESS = "VEHICLETYPE_CREATE_SUCCESS";
export const VEHICLETYPE_CREATE_FAILURE = "VEHICLETYPE_CREATE_FAILURE";
export const VEHICLETYPE_CREATE_REQUEST = "VEHICLETYPE_CREATE_REQUEST";
export const VEHICLETYPE_CREATE_RESET = "VEHICLETYPE_CREATE_RESET";
export const VEHICLETYPE_UPDATE_SUCCESS = "VEHICLETYPE_UPDATE_SUCCESS";
export const VEHICLETYPE_UPDATE_FAILURE = "VEHICLETYPE_UPDATE_FAILURE";
export const VEHICLETYPE_UPDATE_REQUEST = "VEHICLETYPE_UPDATE_REQUEST";
export const VEHICLETYPE_UPDATE_RESET = "VEHICLETYPE_UPDATE_RESET";


// import Toaster from '../views/notifications/toaster/Toaster';


export const vehicleTypesGetRequest = () => ({
    type: VEHICLETYPES_GET_REQUEST,
    loading: true,
    data: []
});

export const vehicleTypesGetSuccess = (data, pageNo, pageSize) => ({
    type: VEHICLETYPES_GET_SUCCESS,
    loading: false,
    data: data
});

export const vehicleTypesGetFailure = () => ({
    type: VEHICLETYPES_GET_FAILURE,
    loading: false,
    data: []
});


export const getvehicletypes = (pageNo = 1, pageSize = 20) => (dispatch, getState) => {
    toastr.remove()
    dispatch(vehicleTypesGetRequest())
    Service.get("vehicle/type?offset=0&limit=500",
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                // console.log(response);
                let data = {
                    vehicletype: response.data,
                    pagination: response.pagination
                }
                dispatch(vehicleTypesGetSuccess(data));
            } else {
               // console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};



export const vehicleTypeGetRequest = () => ({
    type: VEHICLETYPE_GET_REQUEST,
    data: []
});

export const vehicleTypeGetSuccess = (data) => ({
    type: VEHICLETYPE_GET_SUCCESS,
    data: data
});

export const vehicleTypeGetFailure = () => ({
    type: VEHICLETYPE_GET_FAILURE,
    data: []
});
export const vehicleTypeGetReset = () => ({
    type: VEHICLETYPE_GET_RESET,
    data: []
});
/**
 * Get Single Vehicle Type
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getvehicletype = (vehicleTypeId) => (dispatch, getState) => {
   // console.log(vehicleTypeId)
    toastr.remove()
    dispatch(vehicleTypeGetRequest())
    Service.get(`vehicle/type/${vehicleTypeId}`,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Received Successfully');
              //  console.log("response", response);
                dispatch(vehicleTypeGetSuccess(response.data));
            } else {
               // console.log(response);
                dispatch(vehicleTypeGetFailure())
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};




export const vehicleTypeCreateReset = (data) => ({
    type: VEHICLETYPE_CREATE_RESET,
    loading: true
});
export const vehicleTypeCreateRequest = (data) => ({
    type: VEHICLETYPE_CREATE_REQUEST,
    loading: true
});

export const vehicleTypeCreateSuccess = (data) => ({
    type: VEHICLETYPE_CREATE_SUCCESS,
    loading: false,
    created: true
});

export const vehicleTypeCreateFailure = (data) => ({
    type: VEHICLETYPE_CREATE_FAILURE,
    loading: false
});

/**
 * Create  Vehicle Make 
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const createVehicleType = (values) => (dispatch, getState) => {
    dispatch(vehicleTypeUpdateReset());
    toastr.remove()
   // console.log(values);
    dispatch(vehicleTypeCreateRequest());
    Service.post("vehicle/type", values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('New Vehicle Type is added Successfully');
                dispatch(vehicleTypeCreateSuccess());
                dispatch(vehicleTypeCreateReset());
                dispatch(vehicleTypeGetReset());
            } else {
                dispatch(vehicleTypeCreateFailure());
                toastr.error(response.message ? response.message : 'Vehicle Type creation is failed');
            }
        }
    )
};

// /**
//  * Create Vehicle Type
//  * @param  {[type]} fields [description]
//  * @return {[type]}        [description]
//  */
//  export const createVehicleType = (values) => (dispatch, getState) => {
//     toastr.remove()
//     Service.post("admin/vehicles/type", values,
//         (response) => {
//             if (response && response.status && response.status >= 200 && response.status < 300) {
//                 toastr.success(response.message ? 'Created Successfully.' : 'Received Successfully');
//                 console.log(response);
//                 dispatch(vehicleTypeGetSuccess(response.data));
//             } else {
//                 console.log(response);
//                 // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
//                 toastr.error('Something Went Wrong');
//             }
//         }
//     )
// };



/**
 * Update Service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */




export const vehicleTypeUpdateReset = () => ({
    type: VEHICLETYPE_UPDATE_RESET,
    loading: true
});
export const vehicleTypeUpdateRequest = (data) => ({
    type: VEHICLETYPE_UPDATE_REQUEST,
    loading: true
});

export const vehicleTypeUpdateSuccess = (data) => ({
    type: VEHICLETYPE_UPDATE_SUCCESS,
    loading: false,
    updated: true
});

export const vehicleTypeUpdateFailure = (data) => ({
    type: VEHICLETYPE_UPDATE_FAILURE,
    loading: false
});

/**
 * Update  Vehicle Make 
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateVehicleType = (serviceId, values) => (dispatch, getState) => {
    toastr.remove()
   // console.log(values);
    dispatch(vehicleTypeUpdateRequest());
    Service.patch("vehicle/type/" + serviceId, values,
        (response) => {
            if (response && response.status && (response.status >= 200 && response.status < 300)) {
                toastr.success('Vehicle Type is Updated Successfully');
                dispatch(vehicleTypeUpdateSuccess());
                dispatch(vehicleTypeUpdateReset());
                dispatch(vehicleTypeGetReset());
            } else {
                dispatch(vehicleTypeUpdateFailure());
                toastr.error(response.message ? response.message : 'Vehicle Type updation is failed');
            }
        }
    )
};






/**
 * Single service Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteRequest = (data) => ({
    type: VEHICLETYPE_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single service Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteSuccess = (data) => ({
    type: VEHICLETYPE_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single service Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const serviceDeleteFailure = () => ({
    type: VEHICLETYPE_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single service
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deletevehicletypes = (serviceId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(serviceId);
    toastr.remove()
    dispatch(serviceDeleteRequest());
    Service.delete("vehicle/type/" + serviceId, {},
        (response) => {
            if (response && response.status && response.status >= 200 && response.status < 300) {
                toastr.success(response.message ? "Successfully Deleted the Use" : 'Successfully Deleted the Use');
               // console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getvehicletypes(pageNo, pageSize));
            } else {
               // console.log(response);
                dispatch(serviceDeleteFailure());
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error(response.message ? response.message : 'Something Went Wrong');
            }
        }
    )
};