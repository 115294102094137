import toastr from 'toastr';

import Service from "../../../utility/Service"

export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILURE = "USER_GET_FAILURE";
export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USERS_GET_SUCCESS = "USERS_GET_SUCCESS";
export const USERS_GET_FAILURE = "USERS_GET_FAILURE";
export const USERS_GET_REQUEST = "USERS_GET_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_RESET = "USER_UPDATE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";


// import Toaster from '../views/notifications/toaster/Toaster';


export const usersGetRequest = () => ({
    type: USERS_GET_REQUEST,
    loading: true,
    userData: []
});

export const usersGetSuccess = (data, pageNo, pageSize) => ({
    type: USERS_GET_SUCCESS,
    loading: false,
    userData: data
});

export const usersGetFailure = () => ({
    type: USERS_GET_FAILURE,
    loading: false,
    userData: []
});

 // Define a new action type

// export const usersGetReset = () => ({
//     type: USERS_GET_RESET,
//     userData: []
// });

/**
 * Login
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getusers = (value, pageNo = 0, pageSize = 10) => (dispatch, getState) => {
    toastr.remove()
  
    if (value) {
        dispatch(usersGetRequest())
        Service.post("user/all/filter?", value,
            (response) => {
                if (response && response.status && response.status === 200) {
                    // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                    // console.log(response);
                    let data = {
                        users: response.data,
                        pagination: response.pagination
                    }
                    dispatch(usersGetSuccess(data));
                } else {
                    //console.log(response);
                    dispatch(usersGetFailure(response));
                    // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                    toastr.error('Something Went Wrong');
                }
            }
        )
    }
    else {
        let value = {
            "filter": {
                role: "user"
            },
            "offset": 0,
            "limit": 1000

        }
        dispatch(usersGetRequest())

        Service.post("user/all/filter?", value,
            (response) => {
                if (response && response.status && response.status === 200) {
                    // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
                    // console.log(response);
                    let data = {
                        users: response.data,
                        pagination: response.pagination
                    }
                    dispatch(usersGetSuccess(data));
                } else {
                   // console.log(response);
                    dispatch(usersGetFailure(response));
                    // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                    toastr.error('Something Went Wrong');
                }
            }
        )
    }
};


// export const userRequest = () => ({
//     type: USER_RESET,
//     userData: {}
// });

export const userGetRequest = () => ({
    type: USER_GET_REQUEST,
    loading: true,
    userData: {}
});

export const userGetSuccess = (data) => ({
    type: USER_GET_SUCCESS,
    loading: false,
    userData: data
});

export const userGetFailure = (data) => ({
    type: USER_GET_FAILURE,
    loading: false,
    userData: {}
});

/**
 * Get Single User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const getuser = (userId) => (dispatch, getState) => {
    toastr.remove()
    // dispatch(userRequest());
    dispatch(userGetRequest())
    Service.get("user/" + userId,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
               // console.log(response);
                dispatch(userGetSuccess(response.data));
            } else {
                //console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};


export const userUpdateRequest = () => ({
    type: USER_UPDATE_REQUEST,
    loading: true,
    userData: {}
});

export const userUpdateSuccess = (data) => ({
    type: USER_UPDATE_SUCCESS,
    loading: false,
    userData: data
});

export const userUpdateFailure = (data) => ({
    type: USER_UPDATE_FAILURE,
    loading: false,
    userData: {}
});


/**
 * Update User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const updateUser = (userId, values) => (dispatch, getState) => {
    toastr.remove()
   // console.log(userId, values);
    dispatch(userUpdateRequest())
    Service.patch("user/" + userId, values,
        (response) => {
            if (response && response.status && response.status === 200) {
                // toastr.success(response.message ? response.message : 'Locale.actions.usersReceivedSuccessfully');
              //  console.log(response);
                dispatch(userUpdateSuccess());
            } else {
              //  console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};









/**
 * Single user Request
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const userDeleteRequest = (data) => ({
    type: USER_DELETE_REQUEST,
    loading: true,
    deleted: false
});
/**
 * Single user Success
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const userDeleteSuccess = (data) => ({
    type: USER_DELETE_SUCCESS,
    loading: false,
    deleted: true
});
/**
 * Single user Failure
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const userDeleteFailure = (data) => ({
    type: USER_DELETE_FAILURE,
    loading: false,
    deleted: false
});

/**
 * Delete Single User
 * @param  {[type]} fields [description]
 * @return {[type]}        [description]
 */
export const deleteuser = (userId, pageNo, pageSize) => (dispatch, getState) => {
    // console.log(userId);
    toastr.remove()
    dispatch(userDeleteRequest());
    // setTimeout(() => {
    //     // dispatch(userDeleteSuccess());
    //     dispatch(getusers(pageNo, pageSize));
    // }, 1000)

    Service.delete("user/"+userId, {},
        (response) => {
            if (response && response.status && response.status === 200) {
                toastr.success(response.message ? response.message : 'Successfully Deleted the Use');
               // console.log(response);
                // dispatch(userDeleteSuccess(response.data.value));
                dispatch(getusers(pageNo, pageSize));
            } else {
                //console.log(response);
                // toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response.message ? response.message : 'Something Went Wrong');
                toastr.error('Something Went Wrong');
            }
        }
    )
};
